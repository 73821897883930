import "../scss/events.scss";
import { parseISO, format } from "date-fns";
import CalendarIcon from "../assets/calendar_icon.svg";

export const NewBuildingCard = ({ event, handleClick }) => {
  const eventTitle = event.attributes.Title;
  const photo =
    process.env.REACT_APP_BASE_LINK +
    (event.attributes.Images.data[0].attributes.formats.small?.url ??
      event.attributes.Images.data[0].attributes.formats?.thumbnail.url);
  const dateObj = format(parseISO(event.attributes.event_date), "do MMM yyyy");
  return (
    <div
      className="eventsCardNewBuilding"
      onClick={() => {
        handleClick(event);
      }}
    >
      <div className="eventsCardImgDiv">
        <img className="landscape" src={photo} alt="" />
      </div>
      <div className="eventsCardContentDiv">
        <div className="newBuildingHeadingContainer">
          <div className="NewBuildingCardtitle">
            <span>{eventTitle} </span>{" "}
          </div>
          <svg
            width="1"
            height="18"
            viewBox="0 0 1 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.5"
              y1="2.18557e-08"
              x2="0.499999"
              y2="18"
              stroke="#C4C4C4"
            />
          </svg>

          <img src={CalendarIcon} alt="" />
          <div className="newBuildingheading">
            <div>{dateObj}</div>
          </div>
        </div>
        <div className="NewBuildingCardSubtitle">
          <span>{event.attributes.Description}</span>
        </div>
      </div>
    </div>
  );
};
