import Carousel from "react-bootstrap/Carousel";
import RightArrow from "../assets/associationpage/rightArrow.svg";
import LeftIcon from "../assets/associationpage/leftArrow.svg";
import "../scss/associationPage.scss";
import { useState, useEffect } from "react";
import { getTestimonies } from "../apis/Testimonies";
import { BASE_LINK } from "../constants/routes";

export const AlumniSection = ({ AlumniSectionConatianerColor }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const numberOfItemsToShow = 3; // Number of items to show at once
  const [alumnies, setAlumnies] = useState([]);

  useEffect(() => {
    const getAlumnies = async () => {
      const response = await getTestimonies();
      setAlumnies(response.map((res) => res.attributes));
    };
    getAlumnies();
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % alumnies.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? alumnies.length - 1 : prevIndex - 1
    );
  };

  const getItemsToRender = () => {
    let itemsToRender = [];
    for (let i = 0; i < numberOfItemsToShow; i++) {
      let index = (currentIndex + i) % alumnies.length;
      itemsToRender.push(
        <div className="AlumniTest" key={Math.random(index)}>
          <img
            className="AlumniTestImg"
            src={BASE_LINK + alumnies[index]?.imgSrc?.data?.attributes?.url}
            alt="alumni_pic"
          />
          <p className="name2">{alumnies[index]?.name}</p>
          <p className="desig2">{alumnies[index]?.designation}</p>
          <p className="testimony2">{alumnies[index]?.testimony}</p>
          <div className="hoverBox"></div>
        </div>
      );
    }
    return itemsToRender;
  };

  return (
    <div className={`AlumniSectionConatianer ${AlumniSectionConatianerColor}`}>
      <p className="AlumniHeading2">Words from alumni</p>
      <div className="AlumniChildContainer">
        <div className="pointer" onClick={() => goToPrev()}>
          <img src={LeftIcon} alt="icon" />
        </div>
        {getItemsToRender()}
        <div className="pointer" onClick={() => goToNext()}>
          <img src={RightArrow} alt="icon" />
        </div>
      </div>

      <div className="MobCrsl">
        <Carousel fade>
          {alumnies?.map((alumni) => (
            <Carousel.Item>
              <div className="MobFlex">
                <div className="AlumniTest">
                  <img
                    className="AlumniTestImg"
                    src={BASE_LINK + alumni?.imgSrc?.data?.attributes?.url}
                    alt="alumni"
                  />
                  <p className="name2">{alumni.name}</p>
                  <p className="desig2">{alumni.designation}</p>
                  <p className="testimony2">{alumni.testimony}</p>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
