import "../scss/lifeatdbtrPage.scss";
import HeroImg from "../assets/lifeAtDbtr/herodbtr.png";
import Alumni1 from "../assets/lifeAtDbtr/alumni1.png";
import Alumni2 from "../assets/lifeAtDbtr/alumni2.png";
import leftArrow from "../assets/associationpage/leftArrow.svg";
import RightArrow from "../assets/associationpage/rightArrow.svg";
import StarRectImg from "../assets/lifeAtDbtr/starRect.png";
import RightVector from "../assets/lifeAtDbtr/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";
import Quote from "../assets/quote.svg";
import { Events } from "../pages/Events";
import Modal from "react-modal";
import { MoreButton } from "./MoreButton";
import { useState } from "react";
import { postAlumniInfo, uploadFile } from "../apis/Alumni";

export const DbtrLifeHero = () => {
  return (
    <div className="heroLifeContainer">
      <img className="dbtrHeroImg" src={HeroImg} alt="hero" />
    </div>
  );
};

export const EventsLifeAtDbtr = () => {
  return (
    <div className="eventsLifeContainer">
      <div className="Maintext eventsTextStyle">Events</div>
      <div>
        <Events />
      </div>
    </div>
  );
};

export const ApjQuote = () => {
  return (
    <div className="apjContainer">
      <div className="ContributionContainer">
        <div className="contributionText contributiontTop">
          <div>
            <img src={Quote} alt="img" />
          </div>
          <div>
            Education is the most powerful weapon which you can use to change
            the world.
          </div>
          <div className="apjText">-A.P.J. Abdul Kalam</div>
        </div>
      </div>
    </div>
  );
};

export const LifeAtDbtrAlumni = () => {
  const navigate = useNavigate();
  function handleRoute() {
    navigate(NAVIGATION_ROUTES.ASSOCIATIONS);
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <div className="academicProgramsContainer" style={{ paddingTop: "1rem" }}>
        <p className="academicsHeading"></p>
        <div className="academicsFlex">
          <div className="acadmeicsChild">
            <div className="academicsGrandChild">
              <p className="headingCardAcademics">
                The DBTR alumni association
              </p>
              <p className="paraCardAcademics">
                DBTR has been a house of knowledge to some of the brightest
                minds around the world. Our alumni are always proud of the fact
                that they started their journey with DBTR.{" "}
              </p>

              <div onClick={handleRoute} style={{ cursor: "pointer" }}>
                <strong>Learn more </strong>
                <img
                  className="VectorLogo"
                  src={RightVector}
                  alt="vectorlogo"
                  width="24px"
                />
              </div>
            </div>
            <img src={Alumni2} alt="academy2" />
          </div>
          <div className="acadmeicsChild">
            <img src={Alumni1} alt="academy1" />
            <div className="academicsGrandChild">
              <p className="headingCardAcademics">Parent teacher association</p>
              <p className="paraCardAcademics">
                We regularly conduct PTA meetings to help parents understand how
                the students are shaping their lives and making progress at
                DBTR.
              </p>

              <div onClick={handleRoute} style={{ cursor: "pointer" }}>
                <strong>Learn more </strong>
                <img
                  className="VectorLogo"
                  src={RightVector}
                  alt="vectorlogo"
                  width="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Kathaigal = () => {
  return (
    <div className="AlumniFrameContainer kathaigal">
      <div className="AlumniFrameElements">
        <p className="SuppHeading">Kathaigal</p>
        <p className="AlumniParaFrame">
          In addition to being bright, our students are also gifted poets who
          can effortlessly spin a tale or two.
        </p>
        <div>
          <img className="pointer" src={leftArrow} alt="leftArrowLogo" />{" "}
          &nbsp;&nbsp;&nbsp;
          <img className="pointer" src={RightArrow} alt="RightArrowLogo" />
        </div>
      </div>
      <div>
        <div className="reactK">
          <img
            className="reactKImg FrameImg"
            src={StarRectImg}
            alt="FrameImg"
          />
          <div className="reactKtext">
            <p>
              The sun shines majestically, just like my DBTR.The moon is so
              bright, just like my DBTR. The world keeps moving and teaching us,
              just like my DBTR Humans are all filled with love, just like my
              DBTRFamilies are always supportive, just like my DBTRAnd a home
              far from home is my school and it is called DBTR.
            </p>
            <p>
              Humans are all filled with love, just like my DBTRFamilies are
              always supportive, just like my DBTRAnd a home far from home is my
              school and it is called DBTR.
            </p>
            <p className="studentRect">Malika III-A</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UploadAlumniInfo = (props) => {
  const { isModalOpen, onClose } = props;
  const [formErrorShow, setFormErrorShow] = useState(false);
  const [alumniInfo, setAlumniInfo] = useState({
    name: "",
    email: "",
    graduation_year: "",
    file: null,
  });
  const handleSubmit = () => {
    let fileFormdata = new FormData();
    fileFormdata.append("files", alumniInfo.file);
    uploadFile(fileFormdata).then((response) => {
      if (response) {
        delete alumniInfo["file"];
        alumniInfo["image"] = response.id;
        const data = { data: alumniInfo };
        postAlumniInfo(data);
        onClose();
      }
    });
  };

  function handleUpload() {
    let err = false;
    for (const key in alumniInfo) {
      if (key === "email") {
        if (alumniInfo[key].split("").includes("@") === false) {
          err = true;
        }
      } else if (key === "name" || key === "graduation_year") {
        if (!alumniInfo[key].trim()) err = true;
      } else if (key === "file") {
        if (!alumniInfo[key]) err = true;
      }
    }
    if (err) {
      setFormErrorShow(true);
    } else {
      setFormErrorShow(false);
      handleSubmit();
    }
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      alignItems: "center",
    },
  };
  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <div className="InputSection">
        <input
          placeholder="Full name*"
          onChange={(e) =>
            setAlumniInfo({ ...alumniInfo, name: e.target.value })
          }
        />
        <input
          placeholder="Email*"
          onChange={(e) =>
            setAlumniInfo({ ...alumniInfo, email: e.target.value })
          }
        />
      </div>
      <div className="graduation">
        <input
          type="year"
          placeholder="Graduation year*"
          onChange={(e) =>
            setAlumniInfo({ ...alumniInfo, graduation_year: e.target.value })
          }
        />
      </div>
      <div className="uploadContainer">
        <div>
          {alumniInfo?.file?.name || "Upload Image* "}
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            onChange={(e) =>
              setAlumniInfo({ ...alumniInfo, file: e.target.files[0] })
            }
          />
          <button
            className="browse"
            onClick={() => document.getElementById("file-upload").click()}
          >
            Browse
          </button>
        </div>
      </div>
      {formErrorShow ? (
        <p className="contactformError">
          Please fill all the required fields with valid information!
        </p>
      ) : null}
      <MoreButton
        title={"Submit"}
        btnClass={"moreButtonFilled moreButtonContainer"}
        clickFunc={handleUpload}
      />
    </Modal>
  );
};
