import React, { useState } from "react";
import Modal from "react-modal";
import { parseISO, format } from "date-fns";
import cx from "classnames";

//img upload
import CloseIcon from "../assets/close.svg";
import "../scss/eventModal.scss";
import Arrow from "../assets/white_arrow.svg";
import "../scss/lifeatdbtrPage.scss";
import { BASE_LINK } from "../constants/routes";

export const EventsModal = ({ isModalOpen, event, closeModal }) => {
  Modal.setAppElement("#root");

  const imageArray = event.attributes.Photos.data;
  let url = BASE_LINK + event.attributes.Photos.data[0].attributes.url;
  const dateObj = format(parseISO(event.attributes.event_date), "do MMM yyyy");
  const [imageSelected, setImageSelected] = useState(0);
  const [selectedUrl, setSelectedUrl] = useState(null);

  const getImage = () => {
    if (selectedUrl == null && isModalOpen) {
      setSelectedUrl(
        BASE_LINK + event.attributes.Photos.data[0].attributes.url
      );
    } else {
      return selectedUrl;
    }
  };
  const resetModal = () => {
    setImageSelected(0);
    setSelectedUrl(null);
    closeModal();
  };
  const customStyles = {
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      background: "rgba(1, 1, 1, 0.6)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      inset: 0,
    },
    content: {
      background: "rgba(1, 1, 1, 0)",
      width: "50rem",
      maxWidth: "calc(100% - 2rem)",
      maxHeight: "calc(100vh - 2rem)",
      overflowY: "fixed",
      position: "relative",
      border: "0px solid #ccc",
      borderRadius: "0.3rem",
    },
  };

  const handleSelect = (index) => {
    setImageSelected(index);
    url = BASE_LINK + imageArray[index].attributes.url;
    setSelectedUrl(url);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="eventModalHeader" onClick={() => resetModal()}>
        <img src={CloseIcon} alt="" />
      </div>
      <div className="eventMiddleDiv">
        <div
          className={cx("eventMiddleNextDiv", {
            disableEventMiddleNextDiv: imageSelected === 0,
          })}
          onClick={() => handleSelect(imageSelected - 1)}
        >
          <img src={Arrow} alt=""></img>
        </div>
        <div className="eventMiddleImgDiv">
          <img src={getImage()} alt="" />
          <div className="eventMiddleImgDivData">
            <span>{event.attributes.Title}</span>
            <span>{dateObj}</span>
          </div>
        </div>
        <div
          className={cx("eventMiddleNextDiv", "rotateClass", {
            disableEventMiddleNextDiv: imageSelected === imageArray.length - 1,
          })}
          onClick={() => handleSelect(imageSelected + 1)}
        >
          <img src={Arrow} alt=""></img>
        </div>
      </div>
    </Modal>
  );
};
