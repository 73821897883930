import React from "react";
import "../scss/staffContainer.scss";

export const LargeUserComponent = ({ staffInfo }) => {
  let url =
    process.env.REACT_APP_BASE_LINK +
    staffInfo.attributes.photo.data[0].attributes.formats.thumbnail.url;

  return (
    <div className="staffLargeContainer">
      <div>
        <img src={url} alt="test" />
      </div>
      <div className="staffLargefInfo">
        <div className="staffLargeInfoTitle">
          {staffInfo.attributes.last_name} {staffInfo.attributes.first_name}
        </div>
        <div className="designationLargeInfo">
          {staffInfo.attributes.designation}
        </div>
      </div>
    </div>
  );
};

export const SmallUserComponent = ({ staffInfo }) => {
  let url =
    process.env.REACT_APP_BASE_LINK +
    staffInfo.attributes.photo.data[0].attributes.url;

  return (
    <div className="staffSmallContainer">
      <div className="profileSmallImgDiv">
        <img src={url} alt="test" />
      </div>
      <div className="staffSmallInfo">
        <div className="staffLargeInfoTitle">
          {staffInfo.attributes.last_name} {staffInfo.attributes.first_name}
        </div>
        <div className="designationSmallInfo">
          {staffInfo.attributes.designation}
        </div>
      </div>
    </div>
  );
};
