import React, { useEffect, useState } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { getAchievers } from "../apis/Students";

import {
  AcademicPrograms,
  AcademicsHeader,
  RulesAndRegulations,
  StudentWonders,
} from "../components/AcademicsPageComponent";

const Academics = () => {
  const [achievers, setAchievers] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const getAchieverInfo = () => {
    if (achievers && achievers.length === 0) {
      getAchievers().then((response) => {
        setAchievers([...achievers, ...response]);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    getAchieverInfo();
  }, []);

  return (
    <div>
      <Header />
      <AcademicsHeader />
      <AcademicPrograms />
      {!isLoading && <StudentWonders students={achievers} />}
      <RulesAndRegulations />
      <Footer />
    </div>
  );
};

export default Academics;
