import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const getSchoolInfo = async () => {
  const url = BASE_LINK + "/api/schools?populate=*";

  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
