import "../scss/submitButton.scss";
import UploadIcon from "../assets/uploadIcon.svg";

export const FormSubmit = (props) => {
  return (
    <label onClick={() => props.onSubmit()}>
      <img src={UploadIcon} alt="" /> Upload now{" "}
    </label>
  );
};
