import React from 'react';
import DonateHeroImg from "../assets/donatePage/donateHero.png"
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { HeroCommonComponent } from '../components/HeroComponent';
import { AckForm, Contribution, DonationCause, DonationInfo, ThreeStep, FaqSection } from '../components/DonatePageComponents';

const Donate = () => {
    return (
        <div>
            <Header/>
            <HeroCommonComponent img={DonateHeroImg} heading={"Together, let’s build DBTR"} para={"Empower students to make their dreams come true by actively offering assistance"}/>
            <ThreeStep/>
            <DonationCause/>
            <DonationInfo/>
            <AckForm/>
            <Contribution/>
            <FaqSection/>
            <Footer/>
            
        </div>
    );
};

export default Donate;