import "../scss/thankyouPage.scss";
import { SocialIcon } from "react-social-icons";
import ThankyouImg from "../assets/Thankyoupage/ThankyouHeader.png";
import ThankyouHeroImg from "../assets/Thankyoupage/ThankyouHero.png";
import CircleImg from "../assets/Thankyoupage/circleBox.png";
import RightArrow from "../assets/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTES, NAV_SUB_ROUTES } from "../constants/routes";

export const ThankyouHeader = () => {
  return (
    <div className="thankyouHeaderContainer">
      <div className="thankyouHeaderChild">
        <div className="Maintext">Thankyou!</div>
        <div className="thankyouText">
          You have successfully completed your donation. Please check your mail
          to learn more.
        </div>
      </div>
      <img className="headerThankyouImg" src={ThankyouImg} alt="header" />
    </div>
  );
};

export const ThankyouHero = () => {
  return (
    <div className="thankyouHeroContainer">
      <img className="thankyouHero" src={ThankyouHeroImg} alt="hero" />
      <div className="thankyouHeroElem">
        <div className="thankyouHeroElemChild">
          <div className="heroHeader">
            Your support will take DBTR to greater heights and help the students
            learn and excel in life.
          </div>
          <div className="contribution">
            With your valuable contribution, we can
          </div>
          <div className="listContribution">
            <ul>
              <li>Fund the construction requirements of the new building</li>
              <li>Help children learn with the best facilities</li>
              <li>Support financial needs of children</li>
            </ul>
          </div>
        </div>
        <div className="thankyouHeroElemBox">
          <img className="circleImg" src={CircleImg} alt="circle" />
          <div className="boxheaderHeroText">Spread the word around!</div>
          <p>Let's bring more people to contribute towards DBTR.</p>
          <div className="socialIcons">
            <SocialIcon url="https://twitter.com" />
            <SocialIcon url="https://gmail.com" />
            <SocialIcon url="https://instagram.com" />
            <SocialIcon url="https://whatsapp.com" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const WishToDo = () => {
  const navigate = useNavigate();

  function handleRoute(route) {
    navigate(route);
    window.scrollTo(0, 0);
  }
  return (
    <div className="wishContainer">
      <div className="Maintext">Wish to do more?</div>

      <div className="thankyouCardContainer">
        <div className="wishChildEle">
          <div className="wishBox">
            <div className="wishBoxE1">Visit DBTR</div>
            <div className="wishBoxE2">
              Spend some quality time with the smart children of DBTR and
              explore the vibrant culture of Mayiladuthurai.
            </div>
            <div
              onClick={() => handleRoute(NAVIGATION_ROUTES.LIFE)}
              className="wishBoxE2"
            >
              <strong>Explore More</strong>&nbsp;&nbsp;
              <img src={RightArrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div className="wishChildEle">
          <div className="wishBox">
            <div className="wishBoxE1">Explore young talents</div>
            <div className="wishBoxE2">
              The young poets of DBTR have penned their thoughts into words. Go
              ahead and immerse yourself into some fine poetry.
            </div>
            <div
              onClick={() => handleRoute(NAVIGATION_ROUTES.ACADEMICS)}
              className="wishBoxE2"
            >
              <strong>Explore More</strong>&nbsp;&nbsp;
              <img src={RightArrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div className="wishChildEle">
          <div className="wishBox">
            <div className="wishBoxE1">Study our history</div>
            <div className="wishBoxE2">
              For more than 120 years, DBTR has been a guiding force for
              children. Our rich history is an open book that any reader can
              enjoy.
            </div>
            <div
              onClick={() => handleRoute(NAV_SUB_ROUTES.SCHOOL_HISTORY)}
              className="wishBoxE2"
            >
              <strong>Explore More</strong>&nbsp;&nbsp;
              <img src={RightArrow} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
