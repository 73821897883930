import "../scss/homePage.scss";
import Logo from "../assets/dbtr-logo.png";
import DonateHeart from "../assets/heart.svg";
import Psq from "../assets/psq.svg";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";
import { SocialIcon } from "react-social-icons";

export const Footer = () => {
  const navigate = useNavigate();
  function handleDonateButtonClick() {
    navigate(NAVIGATION_ROUTES.DONATE);
    window.scrollTo(0, 0);
  }
  function handleAssocButtonClick() {
    navigate(NAVIGATION_ROUTES.ASSOCIATIONS);
    window.scrollTo(0, 0);
  }
  function handleAssocEventsClick() {
    navigate(NAVIGATION_ROUTES.EVENTS);
    window.scrollTo(0, 0);
  }
  function handleAdmButtonClick() {
    navigate(NAVIGATION_ROUTES.ACADEMICS);
    window.scrollTo(0, 0);
  }
  return (
    <div className="footer">
      <div className="footerContainer">
        <div className="school">
          <div className="footerEle">
            <img src={Logo} alt="logo" className="logoDb" />
          </div>
          <div className="footerEle">
            <div className="dbtrinfo">
              DBTR National Higher Secondary School
            </div>
            <div className="Virtuousness">Virtuousness is Life</div>
            <div className="established">
              Established in 1901, DBTR is situated in the temple town of
              Mayiladuthurai.
            </div>
          </div>
        </div>
        <div className="footerEle">
          <div className="quicklinks">QUICK LINKS</div>
          <div className="quicklinksSec" onClick={handleAdmButtonClick}>
            Admissions
          </div>
          <div className="quicklinksSec" onClick={handleAssocButtonClick}>
            Alumni association
          </div>
          <div className="quicklinksSec" onClick={handleDonateButtonClick}>
            Donate
          </div>
          <div className="quicklinksSec" onClick={handleAssocEventsClick}>
            Events
          </div>
        </div>
        <div className="footerEle">
          <div className="quicklinks">CONTACT</div>
          <div className="quicklinksSecAddress">
            <a
              href="https://www.google.com/maps?ll=11.098192,79.654221&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=14503095568551864710"
              target="blank"
            >
              <strong>DBTR NHSS,</strong> Mahadhana Street, Mayiladuthurai,
              Tamilnadu - 609001
            </a>
          </div>
          <div className="quicklinksSecPhone">
            {" "}
            <a href="tel:+914364223272">+91.436.422.3272</a>
          </div>
          <div className="quicklinksSec">
            {" "}
            <a href="mailto:contact@nationalhighschool.in">
              {" "}
              contact@nationalhighschool.in
            </a>
          </div>
        </div>
        <div className="footerEle">
          <div className="quicklinks"></div>
          <div className="Big">Big or small, you can make an impact.</div>
          <div className="bfooter" onClick={handleDonateButtonClick}>
            Donate <img src={DonateHeart} alt="donatebtn" />
          </div>
        </div>
      </div>
      <div className="footerSecondry">
        <div className="footerSecondryEle">
          <div className="Design">
            © DBTR 2023, All Rights Reserved | Sitemap
          </div>
          <div className="logoContainer">
            <div>
              <SocialIcon
                url="https://www.instagram.com/dbtrmayiladuthurai/?igshid=MzRlODBiNWFlZA%3D%3D"
                bgColor="none"
                fgColor="#2B306A"
                className="socialIcon"
                target="_blank"
              />
            </div>
            <div>
              <SocialIcon
                url="https://www.facebook.com/people/DBTR-National-Higher-Secondary-School/100064151197839/"
                bgColor="none"
                fgColor="#2B306A"
                className="socialIcon"
                target="_blank"
              />
            </div>
            <div>
              <SocialIcon
                url="https://www.youtube.com/@dbtrnationalhighschool4340"
                bgColor="none"
                fgColor="#2B306A"
                className="socialIcon"
                target="_blank"
              />
            </div>
          </div>
        </div>
        <div className="Design">
          Designed By &nbsp;
          <img src={Psq} alt="ps" />
        </div>
      </div>
    </div>
  );
};
