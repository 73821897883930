import React, { useState } from "react";
import Modal from "react-modal";
import { parseISO, format } from "date-fns";
import cx from "classnames";

//img upload
import CloseIcon from "../assets/close.svg";
import "../scss/eventModal.scss";
import Arrow from "../assets/leftArrow.svg";

export const ConstructionModal = ({ isModalOpen, event, closeModal }) => {
  Modal.setAppElement("#root");
  console.log("event,", event);
  const imageArray = event.attributes.Images.data;
  let url =
    process.env.REACT_APP_BASE_LINK +
    event.attributes.Images.data[0].attributes.formats.small.url;
  const dateObj = format(parseISO(event.attributes.event_date), "do MMM yyyy");

  const [imageSelected, setImageSelected] = useState(0);
  const [selectedUrl, setSelectedUrl] = useState(null);

  const getImage = () => {
    if (selectedUrl == null && isModalOpen) {
      console.log("yes");
      setSelectedUrl(
        process.env.REACT_APP_BASE_LINK +
          event.attributes.Images.data[0].attributes.formats.small.url
      );
      // return process.env.REACT_APP_BASE_URL  + event.attributes.Photos.data[0].attributes.formats.medium.url
    } else {
      return selectedUrl;
    }
  };
  const resetModal = () => {
    setImageSelected(0);
    setSelectedUrl(null);
    closeModal();
  };
  const customStyles = {
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      background: "rgba(1, 1, 1, 0.6)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      background: "rgba(1, 1, 1, 0)",
      width: "50rem",
      maxWidth: "calc(100% - 2rem)",
      maxHeight: "calc(100vh - 2rem)",
      overflowY: "fixed",
      position: "relative",
      border: "0px solid #ccc",
      borderRadius: "0.3rem",
    },
  };

  const handleSelect = (index) => {
    setImageSelected(index);
    console.log("index", imageSelected, url);
    url =
      process.env.REACT_APP_BASE_LINK +
      imageArray[index].attributes.formats.small.url;
    setSelectedUrl(url);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      //   onAfterOpen={afterOpenModal}
      //   onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="eventModalHeader" onClick={() => resetModal()}>
        <img src={CloseIcon} alt="" />
      </div>
      <div className="eventMiddleDiv">
        <div
          className={cx("eventMiddleNextDiv", {
            disableEventMiddleNextDiv: imageSelected === 0,
          })}
          onClick={() => handleSelect(imageSelected - 1)}
        >
          <img src={Arrow} alt=""></img>
        </div>
        <div className="eventMiddleImgDiv">
          <img src={getImage()} alt="" />
          <div className="constructionDivContainer">
            <div className="modalDescriptionDiv">
              {event.attributes.Title}
              <svg
                width="1"
                height="18"
                viewBox="0 0 1 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="2.18557e-08"
                  x2="0.499999"
                  y2="18"
                  stroke="#C4C4C4"
                />
              </svg>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.77886 1.37696H3.68575V0.745576C3.68575 0.479299 3.83023 0.233126 4.06476 0.0999877C4.2991 -0.0333238 4.5881 -0.0333238 4.82243 0.0999877C5.05696 0.233126 5.20144 0.479295 5.20144 0.745576V1.37679L10.7989 1.37696V0.745572C10.7989 0.479295 10.9434 0.233122 11.1779 0.0999837C11.4122 -0.0333279 11.7012 -0.0333279 11.9356 0.0999837C12.1701 0.233122 12.3146 0.479291 12.3146 0.745572V1.37678L13.2215 1.37696C13.9583 1.37696 14.6651 1.6649 15.1861 2.17752C15.7073 2.69032 16 3.38566 16 4.11064V13.2661C16 13.9911 15.7073 14.6865 15.1861 15.1993C14.6651 15.7119 13.9583 16 13.2215 16H2.77855C2.04167 16 1.33492 15.7119 0.813879 15.1993C0.292659 14.6865 0 13.9911 0 13.2661V4.11064C0 3.38566 0.292672 2.69032 0.813879 2.17752C1.33492 1.66489 2.04167 1.37696 2.77855 1.37696L2.77886 1.37696ZM13.2191 2.8682H12.3122V3.9966L12.3123 3.99642C12.3123 4.2627 12.1679 4.50887 11.9333 4.64201C11.6988 4.77532 11.41 4.77532 11.1755 4.64201C10.9411 4.50887 10.7966 4.2627 10.7966 3.99642V2.86803H5.20147V3.99642C5.20147 4.2627 5.05698 4.50887 4.82246 4.64201C4.58811 4.77532 4.29912 4.77532 4.06478 4.64201C3.83026 4.50887 3.68577 4.2627 3.68577 3.99642V2.86803H2.77888C2.44392 2.86803 2.1227 2.99891 1.88588 3.23208C1.64907 3.46507 1.51586 3.7811 1.51586 4.11066V6.37228H14.4848V4.11066C14.4848 3.78111 14.3516 3.46507 14.1148 3.23208C13.878 2.99891 13.5567 2.86803 13.2218 2.86803L13.2191 2.8682ZM2.77886 14.5089H13.2218C13.5567 14.5089 13.8779 14.3781 14.1148 14.1451C14.3516 13.9119 14.4848 13.5959 14.4848 13.2663V7.86355H1.51584V13.2663C1.51584 13.5959 1.64905 13.9119 1.88587 14.1451C2.12268 14.3781 2.44388 14.5089 2.77886 14.5089Z"
                  fill="white"
                />
              </svg>
              <span className="modalDescriptionSub">{dateObj}</span>
            </div>
            <div className="moadalDescriptionFooter">
              {event.attributes.Description}
            </div>
          </div>
        </div>
        <div
          className={cx("eventMiddleNextDiv", "rotateClass", {
            disableEventMiddleNextDiv: imageSelected === imageArray.length - 1,
          })}
          onClick={() => handleSelect(imageSelected + 1)}
        >
          <img src={Arrow} alt=""></img>
        </div>
      </div>
    </Modal>
  );
};
