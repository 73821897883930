import React from "react";
import "../scss/common.scss"

export const MoreButton = ({title, btnClass, clickFunc}) => {

    return (
        <div className={btnClass} onClick={() => clickFunc()}>
            <span>{title}</span>
        </div>
        
    )
}