import React, { useState } from "react";
import LifeatdbtrHeroImg from "../assets/lifeAtDbtr/herolifeatdbtr2.png";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import {
  ApjQuote,
  DbtrLifeHero,
  EventsLifeAtDbtr,
  Kathaigal,
  LifeAtDbtrAlumni,
  UploadAlumniInfo,
} from "../components/LifeAtDbtr";
import { HeroCommonComponent } from "../components/HeroComponent";
import { FormSubmit } from "../components/submitButton";
import "../scss/common.scss";

const LifeAtDbtr = () => {
  const [uploadForm, setUploadForm] = useState(false);
  const handleUpload = () => setUploadForm(true);
  const handleClose = () => setUploadForm(false);
  return (
    <div>
      <Header />
      <DbtrLifeHero />
      <EventsLifeAtDbtr />
      <ApjQuote />
      <HeroCommonComponent
        height={"height"}
        img={LifeatdbtrHeroImg}
        heading={"Wish to be featured in our Alumni page?"}
        para={"Upload your photos from your time at DBTR now."}
        button={<FormSubmit onSubmit={() => handleUpload()} />}
      />
      <UploadAlumniInfo isModalOpen={uploadForm} onClose={handleClose} />
      <LifeAtDbtrAlumni />
      <Kathaigal />
      <Footer />
    </div>
  );
};

export default LifeAtDbtr;
