import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const getBanner = async () => {
  const url = BASE_LINK + "/api/banners?populate=*";

  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const downloadInvitation = async () => {
  const pdfUrl = `${BASE_LINK}/uploads/DBTR_Invitation_f55745c74c.pdf`;

  axios
    .get(pdfUrl, { responseType: "blob" })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "DBTR_Invitation.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error downloading PDF:", error);
    });
};
