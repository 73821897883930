import React from "react";
import "../scss/newBuildingPage.scss";
import ProjectPlanImg from "../assets/newbuildingPage/pPlan.png";
import Vector from "../assets/academicsPage/vector.svg";
import { FilterChips } from "../components/FilterChips";
import { useState, useMemo, useEffect } from "react";
import {
  NewConstruction,
  getConstructionVideos,
} from "../apis/NewConstruction";
import leftArrow from "../assets/associationpage/leftArrow.svg";
import RightArrow from "../assets/associationpage/rightArrow.svg";
import _ from "lodash";

import { ConstructionModal } from "../components/ConstructionModal";

import PurposeImg from "../assets/newbuildingPage/pexels-element-digital-1370296 1.png";

import { NewBuildingCard } from "./NewBuildingCard";
import { MoreButton } from "./MoreButton";
import { BASE_LINK } from "../constants/routes";

export const PurposeNewBuilding = () => {
  return (
    <div className="purposeContainer">
      <div className="headingTextNewbuilding">Purpose of new building</div>
      <div className="purposeChildren">
        <div className="purposeText">
          <p>
            For over a century, the iconic structure of DBTR has stood as a
            testament to the institution's rich history and unwavering
            commitment to education. However, despite our tireless efforts in
            maintenance, the building is showing signs of age with cracks and
            wear and tear.
          </p>
          <p>
            As caretakers of this great institution, we have decided to stand up
            to the challenge and construct a new building for our students. A
            building that will cater to their dreams and provide a pathway for
            their achievements.
          </p>
        </div>
        <div>
          <img className="purposeImg" src={PurposeImg} alt="pimg" />
        </div>
      </div>
    </div>
  );
};

export const filterEvents = (eventList, selectedFilter) => {
  if (selectedFilter > 0) {
    const testTitle = eventList[selectedFilter - 1].attributes.Title;
    return _.filter(eventList, function (o, index) {
      return o.attributes.Title === testTitle;
    });
  } else {
    return eventList;
  }
};

export const Construction = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loadMore, setLoadMoreBtn] = useState(3);
  const filteredList = useMemo(
    () => filterEvents(eventList, selectedFilter),
    [eventList, selectedFilter]
  );
  const functCall = () => {
    if (eventList && eventList.length === 0) {
      NewConstruction()
        .then((response) => {
          setEventList([...eventList, ...response]);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };
  const handleClick = (selectedIndex) => {
    setSelectedFilter(selectedIndex);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  function setLoadMore() {
    setLoadMoreBtn(loadMore + 3);
  }
  function loadless() {
    setLoadMoreBtn(3);
  }
  useEffect(() => {
    functCall();
  }, []);

  return (
    <div className="purposeContainer2">
      <div>
        <div className="headingTextNewbuilding">Pictures of construction</div>
      </div>
      <div className="constructionFilter">
        <div className="constructionChips">
          <FilterChips events={eventList} handleClick={handleClick} />
        </div>
        <div className="newBuildingCardContainer">
          {filteredList.slice(0, loadMore).map((event) => (
            <NewBuildingCard event={event} handleClick={handleEventClick} />
          ))}
        </div>
        {loadMore > 3 ? (
          <div onClick={loadless}>
            <MoreButton
              title={"Load Less"}
              btnClass={"moreButtonFilled moreButtonContainer"}
            />
          </div>
        ) : (
          <div onClick={setLoadMore}>
            <MoreButton
              title={"Load More"}
              btnClass={"moreButtonFilled moreButtonContainer"}
            />
          </div>
        )}
      </div>
      {selectedEvent && (
        <ConstructionModal
          isModalOpen={isModalOpen}
          event={selectedEvent}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export const VirtualTour = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const numberOfItemsToShow = 3; // Number of items to show at once
  const [constructionVideos, setConstructionVideos] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getConstructionVideos();
        const videoUrls = response?.map(
          (res) => BASE_LINK + res?.attributes?.video?.data.attributes.url
        );
        setConstructionVideos(videoUrls);
      } catch (error) {
        console.error("Error fetching construction videos:", error);
      }
    }
    fetchData();
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % constructionVideos.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? constructionVideos.length - 1 : prevIndex - 1
    );
  };

  const getItemsToRender = () => {
    let itemsToRender = [];
    for (let i = 0; i < numberOfItemsToShow; i++) {
      let index = (currentIndex + i) % constructionVideos?.length;
      itemsToRender.push(
        <div key={Math.random(index)} className="carouselItem">
          <video controls>
            <source src={constructionVideos?.[index]} type="video/mp4" />
          </video>
        </div>
      );
    }
    return itemsToRender;
  };

  return (
    <div className="vtContainer">
      <div className="vtHeadingContainer">
        <div className="headingSubTextNewbuilding">
          Virtual tour of construction
        </div>
        <div>
          <img
            className="pointer"
            src={leftArrow}
            alt="leftArrowLogo"
            onClick={goToPrev}
          />
          &nbsp;&nbsp;&nbsp;
          <img
            className="pointer"
            src={RightArrow}
            alt="RightArrowLogo"
            onClick={goToNext}
          />
        </div>
      </div>
      <div>
        <div className="carousel">{getItemsToRender()}</div>
      </div>
    </div>
  );
};

export const ProjectPlan = () => {
  return (
    <div className="projectPlanContainer">
      <img className="projectImg" src={ProjectPlanImg} alt="plan" />
      <div className="projectEle">
        <div className="headingTextNewbuilding">
          Project plan for new building
        </div>
        <div className="projectCon">
          Browse through our plan and know first-hand on how we plan to build a
          structure that will house the dreams of thousands of our students.
        </div>
        <div>
          <img
            className="VectorLogo"
            src={Vector}
            alt="vectorlogo"
            width="24px"
          />
          <strong>Download project plan PDF</strong>
        </div>
      </div>
    </div>
  );
};
