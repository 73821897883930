import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const submitContactForm = async (requestData) => {
  const url = BASE_LINK + "/api/contact-users";

  return await axios
    .post(url, requestData)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
