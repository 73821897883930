import "../scss/academicsPage.scss";
import Acad1 from "../assets/academicsPage/acad1.png";
import Acad2 from "../assets/academicsPage/acad2.png";
import Vector from "../assets/academicsPage/vector.svg";
import { useEffect, useState } from "react";
import RightArrow from "../assets/associationpage/rightArrow.svg";
import LeftIcon from "../assets/associationpage/leftArrow.svg";

export const AcademicsHeader = () => {
  return (
    <div className="academicsHeader">
      <p className="academicsHeading">Academics</p>
      <p className="theStudentsPara">
        The students of DBTR are equipped with knowledge to think and lead in a
        rapidly changing world
      </p>
      <div className="academicsInfoContainer">
        <div className="academicsInfo">
          100%
          <p className="academicsInfoSub">Pass rate</p>
        </div>
        <div className="academicsInfo">
          10000+
          <p className="academicsInfoSub">Books</p>
        </div>
        <div className="academicsInfo">
          20+
          <p className="academicsInfoSub">IT labs</p>
        </div>
      </div>
    </div>
  );
};

export const AcademicPrograms = () => {
  return (
    <div className="academicProgramsContainer">
      <p className="academicsHeading">DBTR academic programs</p>
      <div className="academicsFlex">
        <div className="acadmeicsChild">
          <img src={Acad1} alt="academy1" />
          <div className="academicsGrandChild">
            <p className="headingCardAcademics">Pre-primary school</p>
            <p className="paraCardAcademics">
              At DBTR, we provide expert coaching, love, and care to help young
              ones take their first step through classes <strong>1 to 5</strong>
            </p>
            <p className="paraCardAcademics">
              Our top priority is to create a comfortable environment for our
              students, empowering them to become the future leaders they are
              meant to be.
            </p>
            <div className="pointer">
              <img
                className="VectorLogo"
                src={Vector}
                alt="vectorlogo"
                width="24px"
              />
              Download application form
            </div>
          </div>
        </div>
        <div className="acadmeicsChild">
          <div className="academicsGrandChild">
            <p className="headingCardAcademics">Secondary school</p>
            <p className="paraCardAcademics">
              As students prepare to face the big challenges in life, it is
              crucial to have the guidance of qualified teachers with over 20
              years of experience.
            </p>
            <p className="paraCardAcademics">
              With the help of these experienced professionals, students are
              inspired to rise up to challenges and complete their secondary
              schooling from classes <strong>6 to 10</strong>
            </p>
            <div className="pointer">
              <img
                className="VectorLogo"
                src={Vector}
                alt="vectorlogo"
                width="24px"
              />
              Download application form
            </div>
          </div>
          <img src={Acad2} alt="academy2" />
        </div>
      </div>
    </div>
  );
};

export const StudentWonders = ({ students }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const numberOfItemsToShow = 3; // Number of items to show at once

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % students.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? students.length - 1 : prevIndex - 1
    );
  };

  const getItemsToRender = () => {
    let itemsToRender = [];
    for (let i = 0; i < numberOfItemsToShow; i++) {
      let index = (currentIndex + i) % students.length;
      itemsToRender.push(
        <div className="studentFlexInfo">
          <img
            src={
              process.env.REACT_APP_BASE_LINK +
              students[index].attributes.photo.data.attributes.formats.thumbnail
                .url
            }
            alt="s1"
          />
          <p className="name2">
            {students[index].attributes.first_name}{" "}
            {students[index].attributes.last_name}
          </p>
          <p className="desig2">
            {students[index].attributes.data.achievement}
          </p>
        </div>
      );
    }
    return itemsToRender;
  };
  return (
    <div className="StudentWondersContainer">
      <p className="academicsHeading">Student wonders</p>
      <div className="studenWondersCarousel">
        {students.length > numberOfItemsToShow && (
          <img src={LeftIcon} alt="icon" className="icon" onClick={goToPrev} />
        )}
        <div className="studentInfo">{getItemsToRender()}</div>
        {students.length > numberOfItemsToShow && (
          <img
            src={RightArrow}
            alt="icon"
            className="icon"
            onClick={goToNext}
          />
        )}
      </div>
    </div>
  );
};

export const RulesAndRegulations = () => {
  return (
    <div className="randrContainer">
      <p className="academicsHeading">Rules and Regulations</p>
      <div className="paraContainer">
        <div className="rPara">
          <div className="widthPara">
            All students must report to school on time and attend all classes
            that are scheduled for them. <hr />
          </div>
          <div className="widthPara">
            Students must always greet their teachers with respect. <hr />
          </div>
        </div>
        <div className="rPara">
          <div className="widthPara">
            Students are expected to only bring their study material to campus.
            Articles such as CDs, magazines etc are not allowed and if brought,
            they will be confiscated <hr />
          </div>
          <div className="widthPara">
            Students must respect the school property. They should not litter in
            and around the campus and should not damage the same. <br />
            <br /> <hr />
          </div>
        </div>
        <div className="widthPara">
          Students should not engage in bullying, use foul language or conduct
          themselves in a violent manner.{" "}
        </div>
      </div>
    </div>
  );
};
