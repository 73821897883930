import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const submitAcknowledgementForm = async (requestData) => {
  const url = BASE_LINK + "/api/acknowledgement-infos";
  return await axios
    .post(url, requestData)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const uploadFile = async (requestData) => {
  const url = BASE_LINK + "/api/upload";
  return await axios
    .post(url, requestData)
    .then((response) => {
      return response.data[0];
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFaq = async () => {
  const url = BASE_LINK + "/api/faq-sections";
  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
