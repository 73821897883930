import React from 'react';
import HeroImg from '../assets/newbuildingPage/hero.png'
import HeroImg2 from '../assets/newbuildingPage/heroPlan2.png'
import { MoreButton } from '../components/MoreButton';


import { Header } from '../components/header';
import {Footer} from '../components/footer'
import { HeroCommonComponent, } from '../components/HeroComponent';
import { Construction, ProjectPlan, PurposeNewBuilding, VirtualTour } from '../components/NewBuildingPageComponent';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../constants/routes';


const NewBuilding = () => {
    const navigate = useNavigate();
    const handleDonateButtonClick = () => {
        navigate(NAVIGATION_ROUTES.DONATE);
        window.scrollTo(0,0);
    }
    
    return (
        <div>
            
            <Header/>
            <HeroCommonComponent heading={"Building dreams of future achievers"} para={"In the pursuit of providing our students the best facilities and amenities"} img={HeroImg}/>
            <PurposeNewBuilding/>
            <Construction/>
            <VirtualTour/>
            <ProjectPlan/>
            <HeroCommonComponent heading={"Let’s build DBTR together"} para={"Your contribution will support the dreams of thousands and positively impact the lives of their loved ones."} img={HeroImg2} button={<MoreButton title={"Donate"} btnClass={'moreButtonFilled moreButtonContainer'} clickFunc={handleDonateButtonClick}/>}/>
            
            <Footer/>
        </div>
    );
};

export default NewBuilding;