import ThreeStepImg from "../assets/donatePage/123.png";
import OneImg from "../assets/donatePage/1.png";
import TwoImg from "../assets/donatePage/2.png";
import ThreeImg from "../assets/donatePage/3.png";
import CardImg from "../assets/donatePage/cardImg.png";
import CardImg2 from "../assets/donatePage/donation2.png";
import CardImg3 from "../assets/donatePage/donation3.png";
import UploadIcon from "../assets/uploadIcon.svg";

import QuoteImg from "../assets/donatePage/quoteImg.png";
import { MoreButton } from "./MoreButton";
import Faq from "react-faq-component";
import { useState, useEffect } from "react";

import "../scss/donatePage.scss";
import { getFaq, submitAcknowledgementForm, uploadFile } from "../apis/Donate";

export const ThreeStep = () => {
  return (
    <div className="threeStepContainer">
      <div className="headingCardAcademics">Three-step donation process</div>
      <img className="threeStepImg" src={ThreeStepImg} alt="threestep" />
      <div className="childThreeStep">
        <div className="grandChildThreeStep">
          <div>
            <img className="seperateThreeImg" src={OneImg} alt="img" />
          </div>
          <div className="headingThreeStep">Know the cause</div>
          <div className="subheadingThreeStep">
            We deeply accept your contribution for three major causes: quality
            education, new building construction requirements and study
            materials.
          </div>
        </div>
        <div className="grandChildThreeStep">
          <div>
            <img className="seperateThreeImg" src={TwoImg} alt="img" />
          </div>
          <div className="headingThreeStep">Make your contribution </div>
          <div className="subheadingThreeStep">
            Your generous contribution empowers DBTR to propel forward, ensuring
            that students are equipped with the resources they need.
          </div>
        </div>
        <div className="grandChildThreeStep">
          <div>
            <img className="seperateThreeImg" src={ThreeImg} alt="img" />
          </div>
          <div className="headingThreeStep">Get confirmation </div>
          <div className="subheadingThreeStep">
            Once your donation is complete, you will get an acknowledgment about
            the important contribution that you have made for the students of
            DBTR
          </div>
        </div>
      </div>
    </div>
  );
};

export const DonationCause = () => {
  return (
    <div className="donationCauseContainer">
      <div className="headingCardAcademics">Donation causes</div>
      <div className="donationCardContainer">
        <div className="donationCard">
          <img className="cardImgDonate" src={CardImg} alt="cardimg" />
          <div className="cardText1">Sponsor High-quality education</div>
          <div className="cardText2">
            Help in supporting education for all children of DBTR and promote
            them to unlock the values of life.
          </div>
        </div>
        <div className="donationCard">
          <img className="cardImgDonate" src={CardImg3} alt="cardimg" />
          <div className="cardText1">New building</div>
          <div className="cardText2">
            We are constructing a new building that houses the needs of all
            children who want to gain quality education.
          </div>
        </div>
        <div className="donationCard">
          <img className="cardImgDonate" src={CardImg2} alt="cardimg" />
          <div className="cardText1">Educational materials for students</div>
          <div className="cardText2">
            All our children require books to learn, grow and impart knowledge
            that makes them better citizens.
          </div>
        </div>
      </div>
    </div>
  );
};

export const DonationInfo = () => {
  return (
    <div className="donateInfoContainer">
      <div className="headingCardAcademics">Donation information</div>
      <div className="donateSubHeading">
        Donations may please be sent through
      </div>
      <div className="donationBoxContainer">
        <div className="donationBox">
          <div className="donationBoxItem1">Demand Draft/Cheque</div>
          <div className="donationBoxItem2">
            Cheques should be in favour of “DBTR NATIONAL TRUST-BUILDING CORPUS
            FUND A/C”
          </div>
          <div className="donationBoxItem3">
            and can be sent to the below address:
          </div>
          <div className="donationBoxItem4">
            DBTR National Trust 20, Mahadana Street, Mayiladuturai-609001
          </div>
        </div>
        <div className="donationBox">
          <div className="donationBoxItem1">NEFT/RTGS</div>
          <div className="donationBoxItem3">
            For NEFT or RTGS: Bank Account Details
          </div>

          <div>
            <p className="donationBoxItem4">
              DBTR National Trust–Building Corpus Fund A/c
            </p>
            <p>
              <span className="donationBoxItem3">Account Number:</span>{" "}
              <span className="donationBoxItem4">500101011108558</span>
            </p>
            <span className="donationBoxItem3">Bank:</span>{" "}
            <span className="donationBoxItem4">
              City Union Bank, Mayiladuthurai
            </span>
            <p></p>
            <p>
              <span className="donationBoxItem3">Branch IFSC:</span>{" "}
              <spann className="donationBoxItem4">CIUB00000051</spann>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const AckForm = () => {
  const [formErrorShow, setFormErrorShow] = useState(false);
  const initialData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    message: "",
    donated_amount: "",
    donation_method: "",
    transaction_id: "",
    transaction_screenshot: [],
  };
  const [formData, setFormData] = useState(initialData);
  const updateFormData = (e) => {
    const { value, name } = e.target;
    if (name === "transaction_screenshot") {
      const { files } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0] || [],
      }));
    } else setFormData({ ...formData, [name]: value });
  };

  function submitData() {
    let fileFormdata = new FormData();
    fileFormdata.append("files", formData.transaction_screenshot);

    uploadFile(fileFormdata).then((response) => {
      if (response) {
        const obj = { ...formData };
        delete obj["transaction_screenshot"];
        obj["transaction_screenshot"] = response.id;
        const dataObj = { data: obj };
        submitAcknowledgementForm(dataObj).then((response) => {
          if (response) {
            setFormData({ ...initialData });
          }
        });
      }
    });
  }

  function handleAcknowledgement() {
    let err = false;
    for (const key in formData) {
      if (key === "email") {
        if (formData[key].split("").includes("@") === false) {
          err = true;
        }
      } else if (key === "contact_number") {
        if (!Number(formData[key])) err = true;
      }
      if (key !== "message" && key !== "transaction_screenshot") {
        if (formData[key] === "") err = true;
      }
    }
    if (err) {
      setFormErrorShow(true);
    } else {
      setFormErrorShow(false);
      submitData();
    }
  }
  return (
    <div className="ackFormConatiner">
      <div className="headingCardAcademics">Acknowledgement form</div>

      <div className="formContaineDonatePage">
        <div className="inputBoxFormContainer">
          <div className="inputB">
            <input
              placeholder="First name*"
              value={formData.first_name}
              name="first_name"
              onChange={(e) => updateFormData(e)}
            />
            <input
              placeholder="Last name*"
              value={formData.last_name}
              name="last_name"
              onChange={(e) => updateFormData(e)}
            />
          </div>
          <div className="inputB">
            <input
              placeholder="Email*"
              value={formData.email}
              name="email"
              onChange={(e) => updateFormData(e)}
            />
            <input
              placeholder="Phone*"
              value={formData.contact_number}
              name="contact_number"
              onChange={(e) => updateFormData(e)}
            />
          </div>
          <div className="donationDetails">Donation details*</div>
          <div className="inputB">
            <input
              placeholder="Amount Donated*"
              value={formData.donated_amount}
              name="donated_amount"
              onChange={(e) => updateFormData(e)}
            />
            <select
              name="donation_method"
              onChange={(e) => updateFormData(e)}
              value={formData.donation_method}
            >
              <option value="" disabled selected className="donationDetails">
                Donation method*
              </option>
              <option value="Demand Draft/Cheque">Demand Draft/Cheque</option>
              <option value="NEFT/RTGS">NEFT/RTGS</option>
            </select>
          </div>
          <div className="inputB">
            <input
              placeholder="Transaction ID*"
              value={formData.transaction_id}
              name="transaction_id"
              onChange={(e) => updateFormData(e)}
            />
            <div class="col-md-6 customfile-col">
              <div className="upload-btn-wrapper">
                <button
                  className={`upload-btn-wrapper-btn ${
                    formData.transaction_screenshot.name
                      ? "upload-file-selected"
                      : ""
                  }`}
                >
                  {formData.transaction_screenshot.name ||
                    "Upload Screen shot*"}
                  <img
                    src={UploadIcon}
                    alt="upload icon"
                    className="uploadIcon"
                  />
                </button>
                <input
                  type="file"
                  name="transaction_screenshot"
                  onChange={(e) => updateFormData(e)}
                />
              </div>
            </div>
          </div>
          <div>
            <textarea
              placeholder="Type your message(optional)"
              className="inputL"
              value={formData.message}
              name="message"
              onChange={(e) => updateFormData(e)}
            />
          </div>
          <div className="mobDonateButton">
            <MoreButton
              title={"Submit"}
              btnClass={"moreButtonFilled moreButtonContainer"}
            />
          </div>
        </div>
        <div>
          {formErrorShow ? (
            <p className="contactformError">
              <sup>*</sup>Please fill all the required fields with valid
              information!
            </p>
          ) : null}
        </div>
        <div className="deskDonateButton">
          <MoreButton
            title={"Submit"}
            btnClass={"moreButtonFilled moreButtonContainer"}
            clickFunc={handleAcknowledgement}
          />
        </div>
      </div>
    </div>
  );
};

export const Contribution = () => {
  return (
    <div className="ContributionContainer">
      <img className="quoteImg" src={QuoteImg} alt="img" />
      <div className="contributionText">
        <div>
          Contribution to our school <i> building </i> is a definite
          contribution to our nation <i>building</i>
        </div>
      </div>
      <div className="section80">
        All donations are eligible for tax benefits under section 80G
      </div>
    </div>
  );
};

export const FaqSection = () => {
  const [data, setData] = useState({
    title: "",
    rows: [],
  });
  const getData = async () => {
    const response = await getFaq();
    setData({
      ...data,
      rows: response.map((res) => {
        return {
          title: res?.attributes?.title,
          content: res?.attributes?.content,
        };
      }),
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const styles = {
    titleTextColor: "black",
    rowTitleColor: "black",
    arrowColor: "red",
  };

  const config = {
    animate: false,
  };

  return (
    <div className="FaqContainer">
      <div className="headingCardAcademics faqText">FAQs</div>
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
};
