import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const getEvents = async () => {
  const url = BASE_LINK + "/api/events?sort[0]=event_date:desc&populate=*";

  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getPageEvents = async () => {
  const url =
    BASE_LINK +
    "/api/events?populate=*&pagination[pageSize]=2&filters[isUpcoming][$eq]=false";

  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
