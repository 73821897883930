import React, {useState, useMemo} from 'react';
import { Heritage, HeroSchool, SchoolHistory, Staff, Trustees, Vision } from '../components/SchoolPageComponent';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { getSchoolInfo } from "../apis/SchoolPage";
import { useEffect } from 'react';
import { getHistory } from "../apis/History";
import {filterStaffType} from "../pages/HistoryPage"




const SchoolPage = () => {

    const [schoolInfo, setSchoolInfo] = useState([]);
    const [staffInfo, setStaffInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const trustee = useMemo(
        () => filterStaffType(staffInfo, "trustee"),
        [staffInfo]
      );

    const staffArray = useMemo(
        () => filterStaffType(staffInfo, "staff"),
        [staffInfo]
      );  
    useEffect(() => {
        functCall()
    })

    const getStaffInfo = () => {
        if(staffInfo && staffInfo.length === 0) {
            getHistory().then((response) => {
                console.log("res", response)
                setStaffInfo([...staffInfo, ...response]);
                setIsLoading(false);
              });
        }
      };

    const functCall = () => {
        if (schoolInfo && schoolInfo.length === 0) {
            getSchoolInfo().then(async (response) => {
                setSchoolInfo([...schoolInfo, ...response]);
                await getStaffInfo()
          });
        }
      };

    return (
       <div>
            <Header/>
            <HeroSchool/>
            <Heritage/>
            {!isLoading && <Vision vision={schoolInfo[0].attributes.vision} mission={schoolInfo[0].attributes.mission}/>}
            <SchoolHistory/>
            <Trustees trusteeInfo={trustee}/>
            <Staff staffInfo={staffArray}/>
            <Footer/>
            
        </div>
    );
};

export default SchoolPage;