import React, { useEffect, useState, useMemo } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { NAVIGATION_ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";

import "../scss/events.scss";
import "../scss/common.scss";
import cx from "classnames";
import _ from "lodash";
import { EventsModal } from "../components/EventsModal";
//#Component imports

import { EventsCard } from "../components/eventsCard";
import { getEvents } from "../apis/Events";
import { FilterChips } from "../components/FilterChips";
import { MoreButton } from "../components/MoreButton";

export const Events = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [lifeatdbtrPage, setLifeatdbtrPage] = useState(
    window.location.pathname === "/life-at-dbtr"
  );
  const [totalVisibleEvents, setTotalVisibleEvents] = useState(
    lifeatdbtrPage ? 2 : 6
  );
  const navigate = useNavigate();
  const filterEvents = (eventList, selectedFilter) => {
    if (selectedFilter > 0) {
      const testTitle = eventList[selectedFilter - 1].attributes.Title;
      return _.filter(eventList, function (o, index) {
        return o.attributes.Title === testTitle;
      });
    } else {
      return eventList.filter((event, index) => index < totalVisibleEvents);
    }
  };
  const filteredList = useMemo(
    () => filterEvents(eventList, selectedFilter),
    [eventList, selectedFilter, totalVisibleEvents]
  );

  //styles
  const btnClass = cx("moreButtonContainer", "moreButtonOutline");

  function handleRoute() {
    navigate(NAVIGATION_ROUTES.EVENTS);
    window.scrollTo(0, 0);
  }

  function handleViewAll() {
    if (lifeatdbtrPage) {
      setLifeatdbtrPage(false);
      handleRoute();
    } else {
      if (totalVisibleEvents >= eventList.length) return;
      setTotalVisibleEvents(totalVisibleEvents + 2);
    }
  }

  useEffect(() => {
    functCall();
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleClick = (selectedIndex) => {
    setSelectedFilter(selectedIndex);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const functCall = () => {
    if (eventList && eventList.length === 0) {
      getEvents().then((response) => {
        setEventList([...response]);
        setIsLoading(false);
      });
    }
  };

  return (
    <div className="eventsContainer" id="events">
      {lifeatdbtrPage ? null : <Header />}
      {lifeatdbtrPage ? null : (
        <div className={`heroBanner ${lifeatdbtrPage ? "" : "eventsBanner"}`}>
          <div className="heroBannerTitle">Our events gallery</div>
          <div className="heroBannerDescription">
            Events at DBTR are filled with joyous occasions, cultural
            gatherings, and learning opportunities that bring us all together.
          </div>
        </div>
      )}

      {!isLoading && (
        <>
          {lifeatdbtrPage ? null : (
            <div className="eventsFilterDiv">
              <FilterChips events={eventList} handleClick={handleClick} />
            </div>
          )}
          <div className="eventsBody">
            {filteredList?.map((event) => (
              <EventsCard event={event} handleClick={handleEventClick} />
            ))}
          </div>
          <div className="btnContainer">
            <MoreButton
              title={`${lifeatdbtrPage ? "View all" : "View more"}`}
              btnClass={`${btnClass} ${
                totalVisibleEvents >= eventList.length ? "disabled" : ""
              }`}
              selectedFilter
              clickFunc={handleViewAll}
            />
          </div>
        </>
      )}
      {selectedEvent && (
        <EventsModal
          isModalOpen={isModalOpen}
          event={selectedEvent}
          closeModal={closeModal}
        />
      )}

      {lifeatdbtrPage ? null : <Footer />}
    </div>
  );
};
