import React, { useState } from "react";
import Logo from "../assets/dbtr-logo.png";
import Menu from "../assets/menu.png";
import "../scss/header.scss";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";
import { DonateButton } from "./HomePageComponent";
import { MoreButton } from "./MoreButton";
export const Header = () => {
  const [show, setHide] = useState(false);
  const navigate = useNavigate();
  let currentRoute = window.location.pathname;

  const handleCsrButton = () => {
    navigate(NAVIGATION_ROUTES.CSR);
  };

  const MenuButton = () => {
    setHide(!show);
  };
  const HeaderTag = ({ headername, route }) => {
    if (currentRoute === route) {
      return <div className="borderBottom">{headername}</div>;
    } else {
      return <div>{headername}</div>;
    }
  };

  const navArray = [
    {
      title: (
        <HeaderTag headername={"The School"} route={NAVIGATION_ROUTES.SCHOOL} />
      ),
      src: `${NAVIGATION_ROUTES.SCHOOL}`,
    },
    {
      title: (
        <HeaderTag
          headername={"Academics"}
          route={NAVIGATION_ROUTES.ACADEMICS}
        />
      ),
      src: `${NAVIGATION_ROUTES.ACADEMICS}`,
    },
    {
      title: (
        <HeaderTag headername={"Life @ DBTR"} route={NAVIGATION_ROUTES.LIFE} />
      ),
      src: `${NAVIGATION_ROUTES.LIFE}`,
    },
    {
      title: (
        <HeaderTag
          headername={"Contact us"}
          route={NAVIGATION_ROUTES.CONTACT_US}
        />
      ),
      src: `${NAVIGATION_ROUTES.CONTACT_US}`,
    },
    {
      title: (
        <MoreButton
          clickFunc={handleCsrButton}
          title={"CSR"}
          btnClass={"moreButtonOutline moreButtonContainer"}
        />
      ),
      src: `${NAVIGATION_ROUTES.CSR}`,
    },
    { title: <DonateButton />, src: `${NAVIGATION_ROUTES.DONATE}` },
  ];

  return (
    <>
      <div className="mob" onClick={MenuButton}>
        <img src={Menu} alt="menu" style={{ width: "22px" }} />
      </div>
      {show && (
        <div className="headerDivMob">
          <div
            className="buttonDivMob"
            onClick={() => navigate(NAVIGATION_ROUTES.HOMEPAGE)}
          ></div>
          {navArray.map((navItem) => {
            return (
              <div
                className="buttonDivMob"
                key={navItem.title}
                onClick={() => navigate(navItem.src)}
              >
                {navItem.title}
              </div>
            );
          })}
        </div>
      )}

      <img
        onClick={() => navigate(NAVIGATION_ROUTES.HOMEPAGE)}
        className="LogoImg"
        src={Logo}
        alt="logo"
      />

      <div className="headerDiv">
        <div className="innerHeader">
          <div
            className="buttonDiv logoMainImg"
            onClick={() => navigate(NAVIGATION_ROUTES.HOMEPAGE)}
          >
            <img src={Logo} className="logoHome" alt="logo" />
          </div>
          <div className="headerOptions">
            {navArray.map((navItem) => {
              return (
                <div
                  className="buttonDiv"
                  key={navItem.title}
                  onClick={() => navigate(navItem.src)}
                >
                  {navItem.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
