import "../scss/contactUsPage.scss";
import { useState } from "react";
import HeroContactImg from "../assets/contactusPage/heroContactUs.png";
import { MoreButton } from "./MoreButton";
import LocationIcon from "../assets/locationIcon.svg";
import { submitContactForm } from "../apis/ContactUs";

export const HeroContactUS = () => {
  return (
    <div className="heroContactContainer">
      <img className="heroContactUS" src={HeroContactImg} alt="hero" />
      <div className="contactHeading">Contact Us</div>
    </div>
  );
};

export const WorkingHours = () => {
  const [formErrorShow, setFormErrorShow] = useState(false);
  const initialData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialData);
  const updateFormData = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  function submitData() {
    const data = { data: formData };
    submitContactForm(data).then((response) => {
      if (response) {
        setFormData({ ...initialData });
      }
    });
  }
  function handleContactUs() {
    let err = false;
    for (const key in formData) {
      if (key === "email") {
        if (formData[key].split("").includes("@") === false) {
          err = true;
        }
      } else if (key === "contact_number") {
        if (!Number(formData[key]) && formData[key]) err = true;
      } else if (
        key === "first_name" ||
        key === "last_name" ||
        key === "message"
      ) {
        if (!formData[key].trim()) err = true;
      }
    }
    if (err) {
      setFormErrorShow(true);
    } else {
      setFormErrorShow(false);
      submitData();
    }
  }
  return (
    <div className="workingContainer">
      <div className="workingChild1">
        <div className="workingText">Working hours</div>
        <div className="timeText">
          Monday to Friday
          <br />
          <strong>8:00 AM to 4:00 PM</strong>
        </div>
        <div className="timeText">
          Saturday
          <br />
          <strong>8:00 AM to 1:00 PM</strong>
        </div>
      </div>
      <div className="workingChild2">
        <div className="inputBoxFormContainer">
          <div className="getInTouch">Get in touch</div>
          <div className="inputB">
            <input
              placeholder="First name*"
              value={formData.first_name}
              name="first_name"
              onChange={(e) => updateFormData(e)}
            />
            <input
              placeholder="Last name*"
              value={formData.last_name}
              name="last_name"
              onChange={(e) => updateFormData(e)}
            />
          </div>
          <div className="inputB">
            <input
              placeholder="Email*"
              value={formData.email}
              name="email"
              onChange={(e) => updateFormData(e)}
            />
            <input
              placeholder="Phone"
              value={formData.contact_number}
              name="contact_number"
              onChange={(e) => updateFormData(e)}
            />
          </div>
          <div>
            <textarea
              placeholder="Message*"
              className="inputL"
              name="message"
              value={formData.message}
              onChange={(e) => updateFormData(e)}
            />
          </div>
          {formErrorShow ? (
            <p className="contactformError">
              Please fill all the required fields with valid information!
            </p>
          ) : null}
          <MoreButton
            title={"Submit"}
            btnClass={"moreButtonFilled moreButtonContainer"}
            clickFunc={handleContactUs}
          />
        </div>
      </div>
    </div>
  );
};

export const Contact = () => {
  return (
    <div className="ContactContainerMap">
      <div className="ContactContainerMapChild">
        <div className="ContactCard">
          <div className="c1">DBTR National Higher Secondary School</div>
          <div className="c2">
            Mahadhana Street, Mayiladuthurai, Tamil Nadu - 609001
          </div>
          <div>
            <p className="c3">Email</p>
            <p className="c4">nationalhighschool@gmail.com</p>
          </div>
          <div>
            <p className="c3">Phone</p>
            <p className="c4">+91.436.422.3272</p>
          </div>
          <div className="c5 pointer">
            <a
              href="https://www.google.com/maps/dir//3MX3%2B7MH+DBTR+National+Higher+Secondary+School,+Mahadhana+St,+Kamarajar+Salai,+Mayiladuthurai,+Tamil+Nadu+609001/@11.098192,79.654221,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a552135bbd83f7f:0xc945589433136986!2m2!1d79.6542394!2d11.0981953?hl=en&entry=ttu"
              target="blank"
            >
              Get directions <img src={LocationIcon} alt="locationicon" />
            </a>
          </div>
        </div>
        <div className="mapLocation">
          <iframe
            title="frame"
            className="locationImg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.2036799434095!2d79.65164637471594!3d11.098192389070798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a552135bbd83f7f%3A0xc945589433136986!2sDBTR%20National%20Higher%20Secondary%20School!5e0!3m2!1sen!2sin!4v1683030596700!5m2!1sen!2sin"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
