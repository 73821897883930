import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const getAssociations = async () => {
  const url = BASE_LINK + "/api/associations?sort[0]=date:asc&populate=*";

  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const postSubscribers = async (data) => {
  const url = BASE_LINK + "/api/subscribers";

  return await axios
    .post(url, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
