import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";

import "../scss/homePage.scss";
import DonateHeart from "../assets/heart.svg";
import Img2 from "../assets/img2.png";
import Img4 from "../assets/img4.png";
import Img5 from "../assets/img5.png";
import RightArrow from "../assets/rightArrow.svg";
import LeftArrow from "../assets/leftArrow.svg";
import Hero from "../assets/hero2.png";
import HistoryGrp from "../assets/historyGrp.png";
import { MoreButton } from "./MoreButton";
import { useNavigate } from "react-router-dom";
import { BASE_LINK, NAVIGATION_ROUTES } from "../constants/routes";
import Download from "../assets/download.svg";
import { downloadInvitation } from "../apis/banner";

export const DonateButton = () => {
  const navigate = useNavigate();
  function handleDonateButtonClick() {
    navigate(NAVIGATION_ROUTES.DONATE);
    window.scrollTo(0, 0);
  }
  return (
    <div className="bfooter" onClick={handleDonateButtonClick}>
      Donate <img src={DonateHeart} alt="donatebtn" />
    </div>
  );
};

export function HeroSection({ banner }) {
  const navigate = useNavigate();
  function handleDonateButtonClick() {
    navigate(NAVIGATION_ROUTES.DONATE);
    window.scrollTo(0, 0);
  }

  return (
    <div className="heroConatinerHome">
      <Carousel controls={false}>
        {banner?.map((e, index) => {
          return (
            <Carousel.Item className="d.block" key={Math.random(index)}>
              <div className="carouselItemHero">
                <div className="imgHomeBannerContainer">
                  <img
                    className="bannerImg"
                    src={
                      process.env.REACT_APP_BASE_LINK +
                      e.attributes.img.data.attributes.url
                    }
                    alt="hero"
                  />
                </div>
                <div className="contentHero">
                  <p className="future">{e.attributes.Title}</p>
                  <p className="paraHeroSection">{e.attributes.description}</p>
                  {index === 2 ? (
                    <div className="downloadIcon" onClick={downloadInvitation}>
                      <img src={Download} alt="download" />
                      Download Invitation
                    </div>
                  ) : (
                    <MoreButton
                      title={"Get Involved"}
                      btnClass={"moreButtonFilled moreButtonContainer"}
                      clickFunc={handleDonateButtonClick}
                    />
                  )}
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export const History = () => {
  return (
    <div className="historyContainer">
      <div className="history">
        <img src={HistoryGrp} alt="history" />
        <div>
          <div className="box1Container">
            <div className="box1">
              <div className="textBox">
                300<p className="innerText">State-toppers</p>
              </div>{" "}
            </div>
            <div className="box1">
              <div className="textBox">
                5 lakh<p className="innerText">Alumni</p>
              </div>{" "}
            </div>
            <div className="box1">
              <div className="textBox">
                3500<p className="innerText">Teachers</p>
              </div>{" "}
            </div>
            <div className="box1">
              <div className="textBox">
                300<p className="innerText">Entrepreneurs</p>
              </div>{" "}
            </div>
            <div className="box1">
              <div className="textBox">
                250<p className="innerText">Global leaders</p>
              </div>{" "}
            </div>
            <div className="box1">
              <div className="textBox">
                6 lakh<p className="innerText">Professionals</p>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GlanceDbtr = () => {
  const arr = [
    {
      title: "Celebrating learning",
      para: "For more than 100 years, DBTR has fostered a culture of learning that pushes every individual to be their best.",
    },
    {
      title: "The DBTR way of life",
      para: "Our students are motivated to go beyond their textbooks and imbibe the values of life.",
    },
  ];

  const [index, setIndex] = useState(0); // Start from the first item
  const nextIndex = (index + 1) % arr.length; // Calculate the next index
  const prevIndex = (index - 1 + arr.length) % arr.length; // Calculate the previous index
  const [next, setNext] = useState(arr[index]);

  const handleClickNext = () => {
    setIndex(nextIndex); // Update the index to the next one
    setNext(arr[nextIndex]);
  };

  const handleClickPrevious = () => {
    setIndex(prevIndex); // Update the index to the previous one
    setNext(arr[prevIndex]);
  };

  return (
    <div className="glaneDbtrContainer">
      <div className="heading">DBTR at a glance</div>
      <div className="glanceMainBox">
        <div className="glanceMainParent">
          <div className="glanceMainChild">
            <div className="glanceMainGrandChild">
              <div className="glanceImg1">
                <img className="Img1" src={Img5} alt="" />
                <div className="glanceText1">The Alumni Association</div>
              </div>
              <div>
                <div className="boxG">
                  <div className="boxGHead">{next.title}</div>
                  <div className="boxGPara">{next.para}</div>
                  <div className="boxGChild">
                    <div className="pointer" onClick={handleClickNext}>
                      <img src={LeftArrow} alt="left" />
                    </div>
                    <div>
                      <strong>{index + 1}</strong>/{arr.length}
                    </div>
                    <div className="pointer" onClick={handleClickPrevious}>
                      <img src={RightArrow} alt="left" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="glanceImg2">
                <img className="Img5" src={Img4} alt="" />
                <div className="glanceText2">Parent Teacher Association</div>
              </div>
            </div>
          </div>

          <div className="glanceImg3">
            <img className="Img2" src={Img2} alt="" />
            <div className="glanceText2">Talent Show</div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export const Hero2 = () => {
  const navigate = useNavigate();
  function handleDonateButtonClick() {
    navigate(NAVIGATION_ROUTES.DONATE);
    window.scrollTo(0, 0);
  }
  return (
    <div className="hero2container">
      <img src={Hero} alt="hero2" />
      <div className="heroItemsContainer">
        <div className="p1"> Help the future generation</div>
        <div className="p2">
          {" "}
          Your contribution can make a difference to thousands of students
          studying at DBTR
        </div>
        {/* <div className='b1'>BUTTON</div> */}
        <MoreButton
          title={"Support Today"}
          btnClass={"moreButtonFilled moreButtonContainer"}
          clickFunc={handleDonateButtonClick}
        />
      </div>
    </div>
  );
};

export const NewBuilding = () => {
  const navigate = useNavigate();
  function handleNewBuildingPage() {
    navigate(NAVIGATION_ROUTES.NEWBUILDING);
    window.scrollTo(0, 0);
  }
  return (
    <div className="newBuildingContainer">
      <div className="newBuildingChild">
        <div className="future">New Building</div>

        <p className="paraBuilding">
          More students are joining us to get the best education in town. And we
          are building a new block to house their needs and dreams. 
        </p>

        <MoreButton
          title={"Explore"}
          btnClass={"moreButtonFilled moreButtonContainer"}
          clickFunc={handleNewBuildingPage}
        />
      </div>
      <div className="mediaNewbuilding">
        <img src={`${BASE_LINK}/uploads/north_side_1_87e68caa3d.png`} />
      </div>
    </div>
  );
};
