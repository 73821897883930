import React, { useEffect, useState } from "react";
import { getPageEvents } from "../apis/Events";

import { Header } from "../components/header";
import { Footer } from "../components/footer";
import {
  AlumniFrame,
  CalenderComp,
  Group,
  PastEvents,
  SubscribeSec,
  TopTextContent,
} from "../components/AssociationPageComponent";
import "../scss/common.scss";

import { SchoolHistory } from "../components/SchoolPageComponent";
import { AlumniSection } from "../components/AlumniSection";
import { Support } from "../components/SupportComp";
import { HeroCommonComponent } from "../components/HeroComponent";
import AssocHero from "../assets/associationpage/assocHero.png";

const Association = () => {
  const [pastEventList, setEventList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getPagedEvents = () => {
    if (pastEventList && pastEventList.length === 0) {
      getPageEvents().then((response) => {
        setEventList([...pastEventList, ...response]);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    getPagedEvents();
  }, []);
  return (
    <div>
      <Header />
      <HeroCommonComponent
        width={"widthHeroText"}
        img={AssocHero}
        heading={"Stay connected for life"}
        para={
          "Seize this opportunity to reestablish connections with your peers,revive past friendships, and rally around the cause of uplifting the school."
        }
      />
      <TopTextContent />
      <CalenderComp />
      {!isLoading && <PastEvents events={pastEventList} />}
      <Support
        headertext={"Support your school, your way"}
        paratext={
          "You have the power to make a positive impact! Join us in creating a brighter future for our school, where numerous children can receive excellent education and exciting opportunities to enhance their learning experiences."
        }
      />

      <AlumniFrame />
      <Group />
      <AlumniSection
        AlumniSectionConatianerColor={"AlumniSectionConatianerWhite"}
      />
      <SchoolHistory />
      <SubscribeSec />
      <Footer />
    </div>
  );
};

export default Association;
