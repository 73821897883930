import HomePage from "../src/pages/homePage"
import AssociationPage from "./pages/Association";
import { Events } from "./pages/Events";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {NAVIGATION_ROUTES, NAV_SUB_ROUTES} from "./constants/routes"
import Academics from "./pages/Academics";
import ThankyouPage from "./pages/ThankyouPage"
import ContactUs from "./pages/ContactUs";
import NewBuilding from "./pages/NewBuilding";
import Csr from "./pages/Csr";
import SchoolPage from "./pages/schoolPage"
import Donate from "./pages/Donate";
import LifeAtDbtr from "./pages/LifeAtDbtr";
import {HistoryPage} from "./pages/HistoryPage"


function App() {
  return (
    
  
      <BrowserRouter>
        <Routes>

          <Route path={NAVIGATION_ROUTES.HOMEPAGE} element={<HomePage />} />
          <Route path={NAVIGATION_ROUTES.NEWBUILDING} element={<NewBuilding />} />
          <Route path={NAVIGATION_ROUTES.SCHOOL} element={<SchoolPage />} />
          <Route path={NAVIGATION_ROUTES.ACADEMICS} element={<Academics />} />
          <Route path={NAVIGATION_ROUTES.CONTACT_US} element={<ContactUs />} />
          <Route path={NAVIGATION_ROUTES.LIFE} element={<LifeAtDbtr />} />
          <Route path={NAVIGATION_ROUTES.CSR} element={<Csr />} />
          <Route path={NAVIGATION_ROUTES.DONATE} element={<Donate />} />
          <Route path={NAVIGATION_ROUTES.EVENTS} element={<Events />} />
          <Route path={NAV_SUB_ROUTES.SCHOOL_HISTORY} element={<HistoryPage />} />
          <Route path={NAV_SUB_ROUTES.THANKYOU} element={<ThankyouPage />} />
          <Route path={NAVIGATION_ROUTES.ASSOCIATIONS} element={<AssociationPage />} />

        </Routes>
      </BrowserRouter>
  
  );
}

export default App;
