import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";

import { Header } from "../components/header";
import { Footer } from "../components/footer";
import "../scss/historyPage.scss";
import { FounderCard } from "../components/HistoryComponents";
import { getHistory } from "../apis/History";
import {
  LargeUserComponent,
  SmallUserComponent,
} from "../components/StaffProfile";

export const filterStaffType = (staffInfo, typeArray) => {
  return staffInfo.filter((item) => {
    return JSON.stringify(item.attributes.post).includes(
      JSON.stringify(typeArray)
    );
  });
};

export const HistoryPage = () => {
  const [staffInfo, setStaffInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFounderIndex, setSelectedFounderIndex] = useState(0);
  const founderArray = useMemo(
    () => filterStaffType(staffInfo, "founder"),
    [staffInfo]
  );
  const presidentArray = useMemo(
    () => filterStaffType(staffInfo, "president"),
    [staffInfo]
  );
  const secretaries = useMemo(
    () => filterStaffType(staffInfo, "secretaries"),
    [staffInfo]
  );
  const headmasters = useMemo(
    () => filterStaffType(staffInfo, "headmasters"),
    [staffInfo]
  );
  const awardArray = useMemo(
    () => filterStaffType(staffInfo, "sarvepalli radhakrishnan award"),
    [staffInfo]
  );

  const handleFounderSelection = (index) => {
    if (index === founderArray.length - 1) {
      setSelectedFounderIndex(index - 1);
    } else if (index <= 0) {
      setSelectedFounderIndex(index + 1);
    } else {
      setSelectedFounderIndex(index + 1);
    }
  };

  const getStaffInfo = () => {
    getHistory().then((response) => {
      setStaffInfo([...staffInfo, ...response]);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getStaffInfo();
  }, []);
  return (
    <div>
      <Header />
      <div className={cx("heroBanner")}>
        <div className="heroBannerTitle">School history</div>
        <div className="heroBannerDescription">
          DBTR was established in 1901 by philanthropist Komal Srinivasa Iyengar
          and since then, it has been shaping the lives of students for
          generations, making it a significant part of our rich history.
        </div>
      </div>
      <div className="bodyContainer">
        <div className="founderDivbodyContainer">
          {founderArray.length > 0 && (
            <FounderCard
              info={founderArray[selectedFounderIndex]}
              handleClick={handleFounderSelection}
              selectedIndex={selectedFounderIndex}
              founderArray={founderArray}
            />
          )}
        </div>
        <div className="presidentDivBodyContainer">
          <span className="presidentDivTitle">
            Past and present trust presidents
          </span>
          <div className="presidentDivDes">
            Our trust is managed by a distinguished group of individuals,
            including educators, medical professionals, lawyers, chartered
            accountants, and entrepreneurs.
          </div>
          <div className="presidentProfile">
            {presidentArray.length > 0 &&
              presidentArray.map((staff) => {
                return <LargeUserComponent staffInfo={staff} />;
              })}
          </div>
        </div>

        <div className="secetaryDivBodyContainer">
          <span className="presidentDivTitle">
            Past and present school secretaries
          </span>
          <div className="presidentDivDes">
            As gatekeepers of our great school, our secretaries have always
            ensured that an environment of learning is fostered at DBTR.
          </div>
          <div className="presidentProfile">
            {secretaries.length > 0 &&
              secretaries.map((staff) => {
                return <SmallUserComponent staffInfo={staff} />;
              })}
          </div>
        </div>

        <div className="presidentDivBodyContainer">
          <span className="presidentDivTitle">
            Past and present headmasters
          </span>
          <div className="presidentDivDes">
            At DBTR, our students have been fortunate enough to benefit from the
            wisdom and guidance of some truly inspirational principals, whose
            impact on their lives has been significant.
          </div>

          <div className={cx("presidentDivDes", "spaceClass")}>
            Our headmasters have collectively echoed their voices for change and
            ensured that students are always served with valuable chapters that
            change their life for good.
          </div>
          <div className={cx("presidentProfile", "spaceClass")}>
            {headmasters.length > 0 &&
              headmasters.map((staff) => {
                return <SmallUserComponent staffInfo={staff} />;
              })}
          </div>
        </div>

        <div className="secetaryDivBodyContainer">
          <span className="presidentDivTitle">
            Recipients of Sarvepalli Radhakrishnan Award
          </span>
          <div className="presidentDivDes">
            We have always been fortunate enough to be guided by some
            outstanding teachers. Here are a few remarkable individuals who have
            been bestowed with the SARVEPALLI RADHA KRISHNA award in recognition
            of their commitment to inspire young minds to dream big.
          </div>

          <div className={cx("presidentProfile", "spaceClass")}>
            {awardArray.length > 0 &&
              awardArray.map((staff) => {
                return <SmallUserComponent staffInfo={staff} />;
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
