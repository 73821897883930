import "../scss/csrPage.scss";
import ReadIcon from "../assets/csrPage/downIcon.svg";
import MissionImg from "../assets/csrPage/mission.png";
import FocusImg from "../assets/csrPage/focus.png";
import FocusImg2 from "../assets/csrPage/focus2.png";
import { useState } from "react";
import { BASE_LINK } from "../constants/routes";

export const Mission = () => {
  return (
    <div className="missionParentContainer">
      <div className="missionContainer">
        <div>
          <img className="missionImg" src={MissionImg} alt="mission" />
        </div>
        <div className="missionEle">
          <div className="headingCardAcademics">Our mission</div>
          <div className="paraCardAcademics">
            DBTR serves the educational requirements of approximately 3000
            students. However, the buildings that were built 70 years ago are in
            a state of disrepair. Our objective is to overcome these obstacles
            and fulfill the needs of the expanding student population.
          </div>
        </div>
      </div>
    </div>
  );
};

export const Focus = () => {
  return (
    <div>
      <div className="academicsHeading focusArea">Focus area</div>
      <div className="focusContainer">
        <div>
          <img className="missionImg" src={FocusImg} alt="mission" />
        </div>
        <div className="missionEle">
          <div className="focusHeading">Support education </div>
          <div className="paraCardAcademics">
            <p>
              Companies such as yours can contribute to our school under their
              Corporate Social Responsibility (CSR) program. Your donation can
              make a significant difference in shaping the future of our
              students and empowering them to achieve their full potential.
            </p>
            <p>
              Join us in building a brighter future for our students by donating
              to our school under your CSR program. We thank you in advance for
              your support and look forward to collaborating with you
            </p>
          </div>
        </div>
      </div>
      <div className="focusContainer2">
        <div className="missionEle">
          <div className="focusHeading">Support our infra </div>
          <div className="paraCardAcademics">
            <p>
              A new building has become a necessity to meet the needs of our
              students, many of whom are from low-income families. As an aided
              school, the Trust has been providing education for our students
              and we need your support to construct a building that serves as a
              home of education.
            </p>
          </div>
        </div>
        <div>
          <img className="missionImg" src={FocusImg2} alt="mission" />
        </div>
      </div>
    </div>
  );
};

export const MessageTrustee = () => {
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const handleShow = () => {
    setShowText(true);
    setShowButton(false);
  };
  const handleHide = () => {
    setShowText(false);
    setShowButton(true);
  };
  let show;
  show = (
    <div className="extraContent">
      <p>
        We ought to complete the entire construction with your generous
        contributions and move the students into the new School building by the
        end of Sep 2023.
        <br />
        <br />
        You can contribute through funding of-
        <br />
        <br />
        <strong>1- Classroom -Rs.15 lac</strong>
        <br />
        (Each Lab - Rs.35 Lac) 4 labs x 35Lac=1.40 cr (Each Restroom -Rs.20 lac)
        7 Restroom x 20Lac=1.40 cr
        <br />
        <strong>2- Staff room- 40 lac</strong>
        <br />
        Auditorium - 45 lac = 85 lac in One floor
        <br />
        <br />
        We request you to kindly extend your fullest support by way of
        sanctioning maximum funds under your CSR Scheme (Corporate Social
        Responsibility) (CSR Regn. No.00021193 copy enclosed)and thus help us in
        the noble cause of imparting education to the poor students of rural
        areas
        <br />
        <br />
        We would like to state that exemption under Section 80(G) of the Income
        Tax Act, 1961 in respect of donations to D.B.T.R. National Trust has
        been granted vide C.No.7162E (166)/1998-99/CIT-II/TRY, dated 16-06-2011,
        of the Commissioner of Income Tax-II, Tiruchirappalli.
        <br />
        <br />
        Donations for this good cause may please be sent by Demand Draft/Cheque
        /NEFT / RTGS.
        <br />
        <br />
        Cheques should be in favour of “DBTR NATIONAL TRUST-BUILDING CORPUS FUND
        A/C” and can be sent to the below address:
        <br />
        <br />
        ADDRESS
        <br />
        DBTR NATIONAL TRUST
        <br />
        20, MAHADANA STREET, MAYILADUTURAI-609001.
        <br />
        <br />
        For NEFT or RTGS: BANK ACCOUNT DETAILS:
        <br />
        Account Number: 500101011108558
        <br />
        Bank: City Union Bank, Mayiladuthurai <br />
        Name: DBTR National Trust–Building Corpus Fund A/c IFSC: CIUB0000005
        <br />
        <br />
        Request you to support us generously and make this mission get completed
        successfully. Your name will remain in the hearts of many students’ and
        their Families for years together. Your help will go a long way for so
        many decades to come and have an excellent opportunity to positively
        impact the lives of thousands of students every year and contribute
        immensely to the progress of our great country.
        <br />
        <br />
        You will also get the benefits of satisfying the CSR requirements and
        availing of the Sec 80G benefits. For further details, you may please
        feel free to contact the undersigned immediately. <br />
        <br />
        Thanking you
        <br />
        Yours faithfully,
        <br />
        <br />
        <strong>
          VIJAIKUMAR. Soo
          <br />
          Trustee
          <br />
          Mobile: 8610105665(Trust) 9842423395(Personal)
          <br />
        </strong>
      </p>
      <div onClick={handleHide} style={{ cursor: "pointer" }}>
        {" "}
        <img className="uppArrowIcon" src={ReadIcon} alt="icon" />{" "}
        <strong>Read less</strong>
      </div>
    </div>
  );
  let hide;
  hide = (
    <div onClick={handleShow} style={{ cursor: "pointer" }}>
      <img src={ReadIcon} alt="icon" /> <strong>Read more</strong>
    </div>
  );
  return (
    <div className="messageTrusteeContainer">
      <div className="headingCardAcademics">Message from our trustee</div>
      <div className="maintrustee">
        <div>
          <img
            className="trusteeImg"
            src={`${BASE_LINK}/uploads/trustee_d77af896d0.png`}
            alt="trustee"
          />
        </div>
        <div className="trusteeText">
          <div className="trusteeMain">
            “Contribution to our School building is a definite contribution to
            our Nation building”
          </div>
          <div className="paraCardAcademics">
            The new Building's total estimated area of construction will be
            around  57,000 Sq.Ft. and the cost of construction and other
            expenses would be around Rs.15 Crores. We have collected so far Rs.
            6.4 crores mainly through contributions from Alumni, Donors and
            through some initial CSR support. We have completed the basement and
            basic structures for G+3 floors
          </div>

          {showButton ? hide : ""}
        </div>
      </div>
      {showText ? show : ""}
    </div>
  );
};
