import React, {useState } from "react";
import "../scss/filterChips.scss";
import {uniq, map} from "lodash"
import cx from "classnames";
export const FilterChips = ({ events, handleClick, selectedFilter=0}) => {
  let filterList = [{attributes:{Title:"All"}}, ...events];
  const [selectedIndex, setSelectedIndex] = useState(selectedFilter)

  return (
    <>
      {uniq(
        map(filterList, (event) => {
          return event.attributes.Title;
        })
      ).map((event, index) => (
        <div
          className={cx('filterChipsContainer', {'filterChipsSelected': selectedIndex === index})}
          onClick={() => {
            handleClick(index);
            setSelectedIndex(index)
          }}
        >
          <span>{event}</span>
        </div>
      ))}
    </>
  );
};
