import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NAV_SUB_ROUTES } from "../constants/routes";

import HeritageImg from "../assets/schoolpage/heritage.png";
import VisionImg from "../assets/schoolpage/vision.svg";
import MissionImg from "../assets/schoolpage/mission.svg";
import SchoolHistoryImg from "../assets/schoolpage/schoolHistory.png";
import HeroSchoolImg from "../assets/schoolpage/heroSchool.png";

import "../scss/schoolPage.scss";
import { MoreButton } from "./MoreButton";

export const HeroSchool = () => {
  return (
    <div className="heroSchoolContainer">
      <img src={HeroSchoolImg} className="heroSchoolImg2" alt="hero" />
      <div className="textHeadingContainer">
        <div className="heroText">A building block of knowledge</div>
      </div>
    </div>
  );
};

export const Heritage = () => {
  return (
    <div className="HeritageContainer">
      <img src={HeritageImg} alt="heroGrad" />
      <div className="Maintext">
        <div className="maintextContainer">
          Our heritage
          <p>
            Diwan Bahadur T Rangachari (DBTR) National Higher Secondary School
            was founded in 1901. With more than 120 years of teaching
            experience, DBTR is a part of the cultural fabric of Mayiladuthurai.
          </p>
          <p>
            By inculcating the highest standards of morality and discipline, we
            help our students achieve their full potential.
          </p>
        </div>
      </div>
    </div>
  );
};

export const Vision = ({ vision, mission }) => {
  return (
    <div className="VisionConatiner">
      <div>
        <img src={VisionImg} alt="vision" />
        <p className="VisionHeading">Vision</p>
        <p className="VisionPara">{vision}</p>
      </div>
      <div>
        <img src={MissionImg} alt="mission" />
        <p className="VisionHeading">Mission</p>
        <p className="VisionPara">{mission}</p>
      </div>
    </div>
  );
};

export const SchoolHistory = () => {
  const navigate = useNavigate();
  const handleBtnCli = () => {
    navigate(NAV_SUB_ROUTES.SCHOOL_HISTORY);

    window.scrollTo(0, 0);
  };
  return (
    <div className="SchoolHistory">
      <div>
        <p className="Maintext">School history</p>
        <p className="ParaText">
          DBTR was established in 1901 by philanthropist Komal Srinivasa Iyengar
          and since then, it has been shaping the lives of students for
          generations, making it a significant part of our rich history.
        </p>
        <MoreButton
          title={"Explore more"}
          btnClass={"moreButtonFilled moreButtonContainer"}
          clickFunc={handleBtnCli}
        />
      </div>
      <div>
        <img src={SchoolHistoryImg} alt="school" />
      </div>
    </div>
  );
};

export const Trustees = ({ trusteeInfo }) => {
  return (
    <div className="TrusteesContainer">
      <div className="Maintext">Board of trustees</div>
      <div className="textT">
        Our trust is composed of distinguished individuals such as educators,
        medical professionals, lawyers, certified accountants, and business
        owners. Together, they proficiently oversee the operations of three
        schools, namely National Elementary School, DBTR, and Ganapathy National
        Middle School in Kuttalam.
      </div>
      <div className="MembersContainer">
        {trusteeInfo.map((empData) => {
          return (
            <>
              <div className="MembersCard">
                <div className="boxheadtext">
                  {empData.attributes.first_name} {empData.attributes.last_name}
                </div>
                <div className="boxParaText">
                  {empData.attributes.designation}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export const Staff = ({ staffInfo }) => {
  const [filteredStaff, setFilteredStaff] = useState([]);
  const staffSize = 12;

  const handleViewAll = () => {
    setFilteredStaff(staffInfo);
  };
  useEffect(
    () =>
      setFilteredStaff(staffInfo.filter((staff, index) => index < staffSize)),
    [staffInfo]
  );

  return (
    <div className="TrusteesContainer" style={{ backgroundColor: "white" }}>
      <div className="Maintext">Our staff</div>

      <div className="MembersContainer">
        {filteredStaff.map((empData) => {
          return (
            <>
              <div className="MembersCardStaff">
                <div className="boxheadtext">
                  {empData.attributes.first_name} {empData.attributes.last_name}
                </div>
                <div className="boxParaText">
                  {empData.attributes.designation}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div style={{ paddingBottom: "4rem" }}></div>
      {staffInfo.length > staffSize && (
        <MoreButton
          title={"View more"}
          btnClass={"moreButtonFilled moreButtonContainer"}
          clickFunc={handleViewAll}
        />
      )}
    </div>
  );
};
