import { DonateButton } from "./HomePageComponent";
import SupportImg from '../assets/associationpage/Support.png'

export const Support = ({headertext,paratext,paratext2,bgclass, textcolor}) => {
    return (
      <div className={`SupportContainer ${bgclass}`}>
        <div>
          <img src={SupportImg} alt="supportImg" className="SuppImg" />
        </div>
        <div className="SupportSideElem">
          <p className={`SuppHeading ${textcolor}`}>{headertext}</p>
          <p className={`SuppPara ${textcolor}`}> 
            {paratext}
          </p>
          <div>
            <DonateButton/>
          </div>
          <br/>
          {paratext2 && 
          <div className={`${textcolor} dashedBox`}>
              <strong>{paratext2}</strong>
          </div>
          }
        </div>
      </div>
    );
  };