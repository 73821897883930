import React from "react";
import "../scss/common.scss";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import CsrHero from "../assets/csrPage/heroCsr.png";
import { HeroCommonComponent } from "../components/HeroComponent";
import { Focus, MessageTrustee, Mission } from "../components/CsrPageComponent";
import { Support } from "../components/SupportComp";

const Csr = () => {
  return (
    <div>
      <Header />
      <HeroCommonComponent
        img={CsrHero}
        heading={"Gift a better tomorrow"}
        para={`Invest in education as part of your CSR commitment`}
        titleClass="titleClass"
        paraClass="paraClass"
      />
      <Mission />
      <Focus />
      <Support
        textcolor={"textcolor"}
        bgclass={"bgcolor"}
        headertext={"Make change happen"}
        paratext={
          "Join us in building a brighter future for our students by donating to our school under your CSR program. We thank you in advance for your support and look forward to collaborating with you."
        }
        paratext2={
          "All donations are eligible for tax benefits under section 80G"
        }
      />
      <MessageTrustee />
      <Footer />
    </div>
  );
};

export default Csr;
