const NAVIGATION_ROUTES = {
  HOMEPAGE: "/",
  SCHOOL: "/school",
  ACADEMICS: "/academics",
  LIFE: "/life-at-dbtr",
  CONTACT_US: "/contact-us",
  CSR: "/csr",
  DONATE: "/donate",
  EVENTS: "/events",
  ASSOCIATIONS: "/association",
  NEWBUILDING: "/new-building",
};
const NAV_SUB_ROUTES = {
  SCHOOL_HISTORY: "/school/history",
  THANKYOU: "/donate/thankyou",
};

const BASE_LINK = process.env.REACT_APP_BASE_LINK;

export { NAVIGATION_ROUTES, NAV_SUB_ROUTES, BASE_LINK };
