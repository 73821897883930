import "../scss/events.scss";
import { parseISO, format } from 'date-fns' 
import CalendarIcon from "../assets/calendar_icon.svg"

export const EventsCard = ({event, handleClick}) => {

    const eventTitle = event.attributes.Title
    const photo = process.env.REACT_APP_BASE_LINK + event.attributes.Photos.data[0].attributes.url
    const dateObj = format(parseISO(event.attributes.event_date), 'do MMM yyyy')
  return (
    <div className="eventsCard" onClick={() => {handleClick(event)}}>
        <div className="eventsCardImgDiv">
            <img className="landscape" src={photo} alt=""/>
        </div>
        <div className="eventsCardContentDiv">
            <div className="eventsCardTitleDiv"><span>{eventTitle}</span></div>
            <div className="eventsCardDateDiv">
                <img src={CalendarIcon} alt=""/>
                <span>{dateObj}</span>
            </div>
        </div>
    </div>
  );
};
