import axios from "axios";
import { BASE_LINK } from "../constants/routes";

export const getHistory = async () => {
  const url =
    BASE_LINK +
    "/api/staff-infos?populate=*&pagination[pageSize]=50&sort[0]=to_year:asc";
  return await axios
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
