import React from "react";

import { Header } from "../components/header";
import { Footer } from "../components/footer";
import {
  Contact,
  HeroContactUS,
  WorkingHours,
} from "../components/ContactUsPageComponent";

const ContactUs = () => {
  return (
    <div>
      <Header />
      <HeroContactUS />
      <WorkingHours />
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactUs;
