import React from "react";
import { useState } from "react";
import { EventsCard } from "../components/eventsCard";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";
import { format, parseISO } from "date-fns";

import "../scss/associationPage.scss";
import AssocHero from "../assets/associationpage/assocHero.png";
import CalenderLogo from "../assets/associationpage/calenderlogo.svg";
import LocationLogo from "../assets/associationpage/locationlogo.svg";
import Line from "../assets/associationpage/line.svg";
import SupportImg from "../assets/associationpage/Support.png";
import AlumniFrameImg from "../assets/associationpage/AlumniFrame.png";
import leftArrow from "../assets/associationpage/leftArrow.svg";
import RightArrow from "../assets/associationpage/rightArrow.svg";
import GroupImg from "../assets/associationpage/whatsapp-group.png";

import { MoreButton } from "./MoreButton";
import { DonateButton } from "./HomePageComponent";
import { useEffect } from "react";
import { getAssociations, postSubscribers } from "../apis/Association";

export const AssociationHero = () => {
  return (
    <div className="AssocHeroContainer">
      <div>
        <img src={AssocHero} alt="assocHero" />
      </div>
      <p className="AssociationHeroHeading">Stay connected for life</p>
      <p className="AssociationHeroPara">
        Seize this opportunity to reestablish connections with your peers,
        revive past friendships, and rally around the cause of uplifting the
        school.
      </p>
    </div>
  );
};

export const TopTextContent = () => {
  return (
    <div className="TopTextContainer">
      <p className="TopTextPara1">
        Welcome to our alumni association in this corner of the world. We are
        setting up a space that can create opportunities to rekindle old
        friendships, reconnect with your peers, and a venue for you to support
        the school in whichever way possible. We will also be updating the
        various events set to happen to engage with our fellow alumni all over
        the world.
      </p>
      <div className="line"></div>

      <p className="TopTextPara2">
        Join us today and be a part of a vibrant community that fosters lifelong
        connections and celebrates our shared legacy.
      </p>
    </div>
  );
};

export const CalenderComp = () => {
  const [associations, setAssociations] = useState([]);
  const fetchEvents = async () => {
    const res = await getAssociations();
    setAssociations(
      res?.filter((val) => new Date(val?.attributes.date) > new Date()) || []
    );
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <div className="CalenderContainer">
      <p className="HeadingCalender">Mark your calender</p>
      <div className="CardConatinerCalender">
        {associations.length > 0 ? (
          associations.map((association) => (
            <div className="CalenderCard">
              <div className="CalenderCardImage">
                <img
                  src={`${process.env.REACT_APP_BASE_LINK}${association.attributes.image.data.attributes.url}`}
                />
              </div>
              <div className="CalenderCardText">
                <div className="CalenderCardText2">
                  {association.attributes.title}
                </div>
                <p className="CalenderCardText3">
                  {association.attributes.description}
                </p>
                <div className="TimeAndLocation">
                  <img src={CalenderLogo} alt="calender" />
                  {format(parseISO(association.attributes.date), "do MMM yyyy")}
                  <img src={Line} alt="line" />
                  <img src={LocationLogo} alt="location" />
                  {association.attributes.location}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="CalenderCardText2">No upcoming Events</div>
        )}
      </div>
    </div>
  );
};

export const PastEvents = ({ events }) => {
  const navigate = useNavigate();
  function handleRoute() {
    navigate(NAVIGATION_ROUTES.EVENTS);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    console.log("pastEventList", events);
  });

  return (
    <div className="PastEventsContainer">
      <div className="HeadingCalender">Past events</div>

      <div class="eventsMarginCard">
        {events.length > 0 &&
          events.map((event) => (
            <EventsCard event={event} handleClick={() => {}} />
          ))}
      </div>
      <MoreButton
        title={"View all"}
        btnClass={"moreButtonOutline moreButtonContainer"}
        clickFunc={handleRoute}
      />
    </div>
  );
};

export const Support = () => {
  return (
    <div className="SupportContainer">
      <div>
        <img src={SupportImg} alt="supportImg" className="SuppImg" />
      </div>
      <div className="SupportSideElem">
        <p className="SuppHeading">Support your school, your way</p>
        <p className="SuppPara">
          You have the power to make a positive impact! Join us in creating a
          brighter future for our school, where numerous children can receive
          excellent education and exciting opportunities to enhance their
          learning experiences.
        </p>
        <div>
          <DonateButton />
        </div>
      </div>
    </div>
  );
};

export const AlumniFrame = () => {
  return (
    <div className="AlumniFrameContainer">
      <div className="AlumniFrameElements">
        <p className="SuppHeading">Alumni in the frame</p>
        <p className="AlumniParaFrame">
          Explore memories as recounted by our alums, who recall the moments
          that captured their hearts and made them fall in love with DBTR.
        </p>
        <div>
          <img className="pointer" src={leftArrow} alt="leftArrowLogo" />{" "}
          &nbsp;&nbsp;&nbsp;
          <img className="pointer" src={RightArrow} alt="RightArrowLogo" />
        </div>
      </div>
      <div>
        <img className="FrameImg" src={AlumniFrameImg} alt="FrameImg" />
      </div>
    </div>
  );
};

export const Group = () => {
  return (
    <div className="GroupContainer">
      <div>
        <p className="GrpHeading">
          Want to be part of our WhatsApp alumni group?
        </p>
        <MoreButton
          title={"Join our group"}
          btnClass={"moreButtonFilled moreButtonContainer"}
        />
      </div>
      <div>
        <img src={GroupImg} alt="grpImg" className="GrpImg" />
      </div>
    </div>
  );
};

export const SubscribeSec = () => {
  const initialData = { name: "", email: "", graduation_year: "" };
  const [formData, setFormData] = useState(initialData);
  function handleSubscription() {
    const obj = { ...formData };
    const dataObj = { data: obj };
    postSubscribers(dataObj).then(() => {
      setFormData({ ...initialData });
    });
  }
  return (
    <div className="SubscribeContainer">
      <p className="SubsHeading">Subscribe to DBTR updates</p>
      <div className="InputSection">
        <input
          placeholder="Full name*"
          name="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <input
          placeholder="Email*"
          name="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <input
          placeholder="Graduation year*"
          name="graduationYear"
          value={formData.graduation_year}
          onChange={(e) =>
            setFormData({ ...formData, graduation_year: e.target.value })
          }
        />
        <MoreButton
          title={"Submit"}
          btnClass={"moreButtonFilled moreButtonContainer"}
          clickFunc={handleSubscription}
        />
      </div>
      <p className="NotePara">
        Note: By submitting your information, you give permission to DBTR to
        send alumni-related emails. Your information will be kept confidential
        and you can unsubscribe at any time.
      </p>
    </div>
  );
};
