import React from 'react';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { ThankyouHeader, ThankyouHero, WishToDo } from '../components/ThankyouPageComponent';
const ThankyouPage = () => {
    return (
        <div>
        <Header/>
        <ThankyouHeader/>
        <ThankyouHero/>
        <WishToDo/>
        <Footer/>
        </div>
    );
};

export default ThankyouPage;