import React from "react";
import cx from "classnames";
import "../scss/historyPage.scss";
import leftArrow from "../assets/associationpage/leftArrow.svg";

export const FounderCard = ({
  info,
  handleClick,
  selectedIndex,
  founderArray,
}) => {
  const photo =
    process.env.REACT_APP_BASE_LINK +
    info.attributes.photo.data[0].attributes.url;
  return (
    <div className="componentBodyContainer">
      <div className="imageDiv">
        <img src={photo} alt=""></img>
      </div>
      <div className="founderInfoSection">
        <div className="contentContainer">
          <div className="founderTitleDiv">Founder</div>
          <div className="commentBlock">{info.attributes.comments}</div>
        </div>
        <div className="founderNavblock">
          <div
            className={cx("buttonBlock", { disableClick: selectedIndex === 0 })}
            onClick={() => handleClick(selectedIndex)}
          >
            <img src={leftArrow} alt="leftArrowLogo" />
          </div>

          <div
            className={cx("buttonBlock", "rotateClass", {
              disableClick: selectedIndex === founderArray.length - 1,
            })}
            onClick={() => handleClick(selectedIndex)}
          >
            <img src={leftArrow} alt="leftArrowLogo" />
          </div>
        </div>
      </div>
    </div>
  );
};
