import "../scss/hero.scss";
export const HeroCommonComponent = ({
  heading,
  para,
  img,
  button,
  height,
  width,
  titleClass,
  paraClass,
}) => {
  return (
    <div className="heroContactContainer">
      <img className={`heroContactUS ${height}`} src={img} alt="hero" />
      <div className="newbuildingELE">
        <div className={`newbuildingHeading ${titleClass}`}>{heading}</div>
        <div className={`heroNewbuildingPara ${width} ${paraClass}`}>
          {para}
        </div>
        <div>{button}</div>
      </div>
    </div>
  );
};
