import React, { useState,useEffect } from 'react';
import { GlanceDbtr, HeroSection, Hero2, History, NewBuilding } from '../components/HomePageComponent';
import { Header } from '../components/header';
import {Footer} from '../components/footer'
import { AlumniSection } from '../components/AlumniSection';
import { getBanner } from '../apis/banner';
import '../scss/common.scss'
const HomePage = () => {
    const [bannerInfo, setBanner] = useState([])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getBanner();
            setBanner(data);
          } catch (error) {
            // Handle error
            console.error("Error in HomePage:", error);
          }
        };
    
        fetchData();
      }, []);
    return (
        <div>
            <Header/>
            <HeroSection banner={bannerInfo}/>
            <History/>
            <GlanceDbtr/>
            <AlumniSection AlumniSectionConatianerColor={'AlumniSectionConatianerBlue'}/>
            <Hero2/>
            <NewBuilding/>
            <Footer/>
            
        </div>
    );
};

export default HomePage;